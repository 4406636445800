import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if showNativeDateInput}}\n  <Input\n    @type='date'\n    id={{inputId}}\n    @value={{inputValue}}\n    class={{inputClass}}\n    placeholder={{placeholder}}\n    required={{required}}\n    disabled={{disabled}}\n  />\n{{else}}\n  <input\n    {{pikaday\n      value=@dateValue\n      onSelect=(action 'onPikadaySelection')\n      format=this.pikadayFormat\n      yearRange=@yearRange\n      i18n=this.pikadayI18n\n    }}\n    id={{@inputId}}\n    placeholder={{@placeholder}}\n    required={{@required}}\n    disabled={{@disabled}}\n    autocomplete='off'\n    class={{@inputClass}}\n  />\n{{/if}}", {"contents":"{{#if showNativeDateInput}}\n  <Input\n    @type='date'\n    id={{inputId}}\n    @value={{inputValue}}\n    class={{inputClass}}\n    placeholder={{placeholder}}\n    required={{required}}\n    disabled={{disabled}}\n  />\n{{else}}\n  <input\n    {{pikaday\n      value=@dateValue\n      onSelect=(action 'onPikadaySelection')\n      format=this.pikadayFormat\n      yearRange=@yearRange\n      i18n=this.pikadayI18n\n    }}\n    id={{@inputId}}\n    placeholder={{@placeholder}}\n    required={{@required}}\n    disabled={{@disabled}}\n    autocomplete='off'\n    class={{@inputClass}}\n  />\n{{/if}}","moduleName":"amber-ui/components/input/date-input.hbs","parseOptions":{"srcName":"amber-ui/components/input/date-input.hbs"}});
import TimeInputComponent from './time-input';
import { computed } from '@ember/object';
import moment from 'moment';
import { oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default TimeInputComponent.extend({
  media: service(),
  momentFieldsToUpdate: ['year', 'month', 'date'],
  showNativeDateInput: oneWay('media.isMobile'),

  // Format the date should be in for the native date input value
  // This is, as specified in http://w3c.github.io/html-reference/input.date.html#input.date.attrs.value, the full-date
  // format as defined in RFC 3339
  inputValueFormat: 'YYYY-MM-DD',

  // Format used to display the date in the pikaday input
  pikadayFormat: 'DD-MM-YYYY',
  pikadayI18n: computed(function () {
    return {
      previousMonth: 'Vorige maand',
      nextMonth: 'Volgende maand',
      months: moment.localeData()._months,
      weekdays: moment.localeData()._weekdays,
      weekdaysShort: moment.localeData()._weekdaysShort,
    };
  }),

  actions: {
    onPikadaySelection(selectedDate) {
      this.updateDateValue(moment(selectedDate));
    },
  },
});
