import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='alert alert-danger' role='alert' data-test-form-closed-alert>\n  <div class='container'>\n    <div class='row'>\n      <strong class='col alert-text'>Je kunt niet reageren!</strong>\n      <span class='alert-text'>\n        Deze inschrijflijst\n        {{lowercase (form-opened-label form)}}\n      </span>\n    </div>\n  </div>\n</div>", {"contents":"<div class='alert alert-danger' role='alert' data-test-form-closed-alert>\n  <div class='container'>\n    <div class='row'>\n      <strong class='col alert-text'>Je kunt niet reageren!</strong>\n      <span class='alert-text'>\n        Deze inschrijflijst\n        {{lowercase (form-opened-label form)}}\n      </span>\n    </div>\n  </div>\n</div>","moduleName":"amber-ui/components/form/response/form-closed-alert.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/form-closed-alert.hbs"}});
import Component from '@ember/component';

const FormClosedAlert = Component.extend({
  form: null,
});

FormClosedAlert.reopenClass({
  positionalParams: ['form'],
});

export default FormClosedAlert;
