import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}\n{{#each pageActions as |pageAction|}}\n  {{page-action pageAction class='col col-md-auto page-action'}}\n{{/each}}", {"contents":"{{yield}}\n{{#each pageActions as |pageAction|}}\n  {{page-action pageAction class='col col-md-auto page-action'}}\n{{/each}}","moduleName":"amber-ui/components/page-actions-buttons.hbs","parseOptions":{"srcName":"amber-ui/components/page-actions-buttons.hbs"}});
import Component from '@ember/component';

const PageActionsButtonsComponent = Component.extend({
  tagName: 'span',
  classNames:
    'page-actions-buttons col col-md-auto row g-0 flex-wrap justify-content-end p-0',
});

PageActionsButtonsComponent.reopenClass({
  positionalParams: ['pageActions'],
});

export default PageActionsButtonsComponent;
