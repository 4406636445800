import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each form.sortedQuestions as |question|}}\n  {{#if question.isOpenQuestion}}\n    {{form/response/open-question\n      question\n      question.linkedAnswer\n      data-test-open-question=question.id\n    }}\n  {{else if question.isMultipleChoice}}\n    {{form/response/multiple-choice-question\n      question\n      question.linkedAnswers\n      updateAnswers=(action 'updateMultipleChoiceAnswers')\n      data-test-closed-question=question.id\n    }}\n  {{else}}\n    {{form/response/closed-question\n      question\n      question.linkedAnswer\n      data-test-closed-question=question.id\n    }}\n  {{/if}}\n{{/each}}", {"contents":"{{#each form.sortedQuestions as |question|}}\n  {{#if question.isOpenQuestion}}\n    {{form/response/open-question\n      question\n      question.linkedAnswer\n      data-test-open-question=question.id\n    }}\n  {{else if question.isMultipleChoice}}\n    {{form/response/multiple-choice-question\n      question\n      question.linkedAnswers\n      updateAnswers=(action 'updateMultipleChoiceAnswers')\n      data-test-closed-question=question.id\n    }}\n  {{else}}\n    {{form/response/closed-question\n      question\n      question.linkedAnswer\n      data-test-closed-question=question.id\n    }}\n  {{/if}}\n{{/each}}","moduleName":"amber-ui/components/form/response/response-form.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/response-form.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';

const FormResponseComponent = Component.extend({
  store: service(),
  actions: {
    updateMultipleChoiceAnswers(question, optionIds) {
      setTimeout(() => {
        const answers = question.get('linkedAnswers');
        const previousOptionIds = answers
          .mapBy('option.id')
          .rejectBy('isDeleted');
        const removedOptionIds = previousOptionIds.reject((id) =>
          optionIds.includes(id)
        );
        const addedOptionIds = optionIds.reject((id) =>
          previousOptionIds.includes(id)
        );

        removedOptionIds.forEach((removedOptionId) => {
          const removedAnswer = answers.findBy('option.id', removedOptionId);
          answers.removeObject(removedAnswer);
          removedAnswer.deleteRecord();
        });

        addedOptionIds.forEach((addedOptionId) => {
          const option = this.store.peekRecord(
            'form/closed-question-option',
            addedOptionId
          );
          const addedAnswer = this.store.createRecord(
            'form/closed-question-answer',
            { response: this.response, option }
          );
          answers.push(addedAnswer);
        });
      }, 10);
    },
  },
});

FormResponseComponent.reopenClass({
  positionalParams: ['form', 'response'],
});

export default FormResponseComponent;
