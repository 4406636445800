import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<table class='table'>\n  <thead>\n    <tr>\n      <th>Model</th>\n      <th colspan='4'>Rechten</th>\n    </tr>\n  </thead>\n  <tbody>\n    {{#each groupedPermissions as |group|}}\n      <tr>\n        <td scope='row'>{{group.model}}</td>\n        {{#each group.permissions as |permission|}}\n          <td>\n            {{permissions/permissions-table-item\n              permission\n              modelPermissions\n              editable\n              inheritedPermissions\n            }}\n          </td>\n        {{/each}}\n        {{#unless (eq group.permissions.length 4)}}\n          <td colspan='4-{{group.permissions.length}}'></td>\n        {{/unless}}\n      </tr>\n    {{/each}}\n  </tbody>\n</table>", {"contents":"<table class='table'>\n  <thead>\n    <tr>\n      <th>Model</th>\n      <th colspan='4'>Rechten</th>\n    </tr>\n  </thead>\n  <tbody>\n    {{#each groupedPermissions as |group|}}\n      <tr>\n        <td scope='row'>{{group.model}}</td>\n        {{#each group.permissions as |permission|}}\n          <td>\n            {{permissions/permissions-table-item\n              permission\n              modelPermissions\n              editable\n              inheritedPermissions\n            }}\n          </td>\n        {{/each}}\n        {{#unless (eq group.permissions.length 4)}}\n          <td colspan='4-{{group.permissions.length}}'></td>\n        {{/unless}}\n      </tr>\n    {{/each}}\n  </tbody>\n</table>","moduleName":"amber-ui/components/permissions/permissions-table.hbs","parseOptions":{"srcName":"amber-ui/components/permissions/permissions-table.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';

export default Component.extend({
  store: service(),
  editable: false,
  modelName: null,
  modelPermissions: null,
  inheritedPermissions: [],
  permissions: computed(function () {
    return this.store.findAll('permission');
  }),
  groupedPermissions: computed(
    'permissions',
    'permissions.@each.model',
    'permissions.@each.action',
    function () {
      const groups = [];

      this.permissions.then((permissions) => {
        permissions.forEach((permission) => {
          const permissionModel = permission.get('model');
          const modelGroup = groups.findBy('model', permissionModel);

          if (!modelGroup) {
            groups.pushObject(
              EmberObject.create({
                model: permissionModel,
                permissions: [],
              })
            );
          }

          groups
            .findBy('model', permissionModel)
            .permissions.pushObject(permission);
        });
      });

      return groups;
    }
  ),
});
