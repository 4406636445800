import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='md-editor-and-toolbar'>\n  <MdEditor::MdEditorToolbar\n    @editMode={{mut editMode}}\n    @applyStyleOption={{this.applyStyle}}\n  />\n\n  {{#if editMode}}\n    <Textarea\n      id={{@textareaId}}\n      @value={{@content}}\n      class='form-control md-editor'\n      rows='15'\n    />\n  {{else}}\n    <div class='alert alert-info mx-2' role='alert'>\n      Heb je een foto ingevoegd in dit artikel? Deze worden pas getoond als je het\n      artikel hebt opgeslagen.\n    </div>\n    <div class=\"px-2\">\n      {{markdown-to-html @content extensions='youtubeEmbed bootstrapTable'}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class='md-editor-and-toolbar'>\n  <MdEditor::MdEditorToolbar\n    @editMode={{mut editMode}}\n    @applyStyleOption={{this.applyStyle}}\n  />\n\n  {{#if editMode}}\n    <Textarea\n      id={{@textareaId}}\n      @value={{@content}}\n      class='form-control md-editor'\n      rows='15'\n    />\n  {{else}}\n    <div class='alert alert-info mx-2' role='alert'>\n      Heb je een foto ingevoegd in dit artikel? Deze worden pas getoond als je het\n      artikel hebt opgeslagen.\n    </div>\n    <div class=\"px-2\">\n      {{markdown-to-html @content extensions='youtubeEmbed bootstrapTable'}}\n    </div>\n  {{/if}}\n</div>","moduleName":"amber-ui/components/md-editor.hbs","parseOptions":{"srcName":"amber-ui/components/md-editor.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MdEditorComponent extends Component {
  editMode = true;

  @action
  applyStyle(option, modalInput = null) {
    const textarea = document.getElementById(`${this.args.textareaId}`);

    const { selectionStart, selectionEnd } = textarea;
    const selection = textarea.value.substring(selectionStart, selectionEnd);

    let styledSelection = option.format.replace('$1', selection);
    if (option.modal) {
      styledSelection = styledSelection.replace('$2', modalInput);
    }

    const styledContent =
      this.args.content.substring(0, selectionStart) +
      styledSelection +
      this.args.content.substring(selectionStart + selection.length);

    this.args.setContent(styledContent);

    textarea.setSelectionRange(
      selectionStart,
      selectionEnd + styledSelection.length - selection.length
    );
    textarea.focus();
  }
}
