import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (eq question.fieldType 'textarea')}}\n  <ModelForm::TextareaInput\n    @model={{answer}}\n    @property='answer'\n    @inputLayout='vertical'\n    @label={{question.question}}\n    @inputIdentifier={{inputIdentifier}}\n    @required={{question.required}}\n  />\n{{else}}\n  <ModelForm::TextInput\n    @type={{question.fieldType}}\n    @model={{answer}}\n    @property='answer'\n    @inputLayout='vertical'\n    @label={{question.question}}\n    @inputIdentifier={{inputIdentifier}}\n    @required={{question.required}}\n  />\n{{/if}}", {"contents":"{{#if (eq question.fieldType 'textarea')}}\n  <ModelForm::TextareaInput\n    @model={{answer}}\n    @property='answer'\n    @inputLayout='vertical'\n    @label={{question.question}}\n    @inputIdentifier={{inputIdentifier}}\n    @required={{question.required}}\n  />\n{{else}}\n  <ModelForm::TextInput\n    @type={{question.fieldType}}\n    @model={{answer}}\n    @property='answer'\n    @inputLayout='vertical'\n    @label={{question.question}}\n    @inputIdentifier={{inputIdentifier}}\n    @required={{question.required}}\n  />\n{{/if}}","moduleName":"amber-ui/components/form/response/open-question.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/open-question.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

const OpenQuestionComponent = Component.extend({
  inputIdentifier: computed('question.id', function () {
    return `question-${this.question.id}`;
  }),
});

OpenQuestionComponent.reopenClass({
  positionalParams: ['question', 'answer'],
});

export default OpenQuestionComponent;
