import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if editable}}\n  <Input\n        @type='checkbox'\n        class='form-check-input'\n        name={{permission.name}}\n        @checked={{hasPermission}}\n        id=\"permission-{{permission.name}}-check\"\n      />\n  <label class='form-check-label' for=\"permission-{{permission.name}}-check\">\n    {{permission.action}}\n  </label>\n{{else}}\n  {{#if (or hasPermission hasInheritedPermission)}}\n    <span class='badge {{if hasPermission \"bg-success\" \"bg-info\"}}'>\n      {{#if hasPermission}}\n        <FaIcon @icon='check' />\n      {{else}}\n        <FaIcon @icon='users' />\n      {{/if}}\n      {{permission.action}}\n    </span>\n  {{else}}\n    <span class='badge badge-default'>\n      <FaIcon @icon='xmark' />\n      {{permission.action}}\n    </span>\n  {{/if}}\n{{/if}}", {"contents":"{{#if editable}}\n  <Input\n        @type='checkbox'\n        class='form-check-input'\n        name={{permission.name}}\n        @checked={{hasPermission}}\n        id=\"permission-{{permission.name}}-check\"\n      />\n  <label class='form-check-label' for=\"permission-{{permission.name}}-check\">\n    {{permission.action}}\n  </label>\n{{else}}\n  {{#if (or hasPermission hasInheritedPermission)}}\n    <span class='badge {{if hasPermission \"bg-success\" \"bg-info\"}}'>\n      {{#if hasPermission}}\n        <FaIcon @icon='check' />\n      {{else}}\n        <FaIcon @icon='users' />\n      {{/if}}\n      {{permission.action}}\n    </span>\n  {{else}}\n    <span class='badge badge-default'>\n      <FaIcon @icon='xmark' />\n      {{permission.action}}\n    </span>\n  {{/if}}\n{{/if}}","moduleName":"amber-ui/components/permissions/permissions-table-item.hbs","parseOptions":{"srcName":"amber-ui/components/permissions/permissions-table-item.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

const PermissionsTableItemComponent = Component.extend({
  permission: null,
  modelPermissions: null,
  inheritedPermissions: [],
  editable: null,
  hasPermission: computed(
    'permission',
    'modelPermissions.@each.permission',
    'modelPermissions.@each.model',
    {
      get() {
        return this.modelPermissions.includes(this.permission);
      },
      set(key, value) {
        if (value && !this.modelPermissions.includes(this.permission)) {
          this.modelPermissions.pushObject(this.permission);
        } else if (this.modelPermissions.includes(this.permission)) {
          this.modelPermissions.removeObject(this.permission);
        }

        return value;
      },
    }
  ),
  hasInheritedPermission: computed(
    'permission',
    'inheritedPermissions.@each.permission',
    'inheritedPermissions.@each.model',
    function () {
      return this.inheritedPermissions.includes(this.permission);
    }
  ),
});

PermissionsTableItemComponent.reopenClass({
  positionalParams: [
    'permission',
    'modelPermissions',
    'editable',
    'inheritedPermissions',
  ],
});

export default PermissionsTableItemComponent;
