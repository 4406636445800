import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<option disabled selected={{not value}}>Selecteer {{placeholder}}</option>\n{{#each options as |opt|}}\n  <option value={{opt.value}} selected={{eq value opt.value}}>\n    {{opt.label}}\n  </option>\n{{/each}}", {"contents":"<option disabled selected={{not value}}>Selecteer {{placeholder}}</option>\n{{#each options as |opt|}}\n  <option value={{opt.value}} selected={{eq value opt.value}}>\n    {{opt.label}}\n  </option>\n{{/each}}","moduleName":"amber-ui/components/input/select-input.hbs","parseOptions":{"srcName":"amber-ui/components/input/select-input.hbs"}});
import Component from '@ember/component';

export default Component.extend({
  tagName: 'select',
  attributeBindings: ['required', 'disabled'],
  required: null,
  options: null,
  value: null,
  placeholder: null,
  change() {
    this.set('value', this.element.value);
  },
});
