import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if hasPermission}}\n  <div {{on 'mouseover' this.showPopup}} {{on 'mouseout' this.hidePopup}}>\n    {{#if (and link linkArgument)}}\n      <LinkTo\n        @route={{link}}\n        @model={{linkArgument}}\n        {{on 'click' this.closeAfterNavigation}}\n      >\n        <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n      </LinkTo>\n    {{else if link}}\n      <LinkTo @route={{link}} {{on 'click' this.closeAfterNavigation}}>\n        <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n      </LinkTo>\n    {{else}}\n      <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n    {{/if}}\n  </div>\n{{/if}}", {"contents":"{{#if hasPermission}}\n  <div {{on 'mouseover' this.showPopup}} {{on 'mouseout' this.hidePopup}}>\n    {{#if (and link linkArgument)}}\n      <LinkTo\n        @route={{link}}\n        @model={{linkArgument}}\n        {{on 'click' this.closeAfterNavigation}}\n      >\n        <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n      </LinkTo>\n    {{else if link}}\n      <LinkTo @route={{link}} {{on 'click' this.closeAfterNavigation}}>\n        <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n      </LinkTo>\n    {{else}}\n      <MenuSidebarLink @minor={{minor}} @title={{title}} @icon={{icon}} />\n    {{/if}}\n  </div>\n{{/if}}","moduleName":"amber-ui/components/menu-sidebar-item.hbs","parseOptions":{"srcName":"amber-ui/components/menu-sidebar-item.hbs"}});
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MenuSidebarItemComponent extends Component {
  @service layoutManager;

  @tracked minor = false;

  @action
  closeAfterNavigation() {
    if (this.minor) {
      this.layoutManager.closeProfileMenu();
    }

    this.layoutManager.closeLeftSidebarIfOnMobile();
  }

  @action
  showPopup() {
    let { layoutManager, element, title } = this;
    if (!this.popup) {
      this.popup = document.createElement('div');
      this.popup.appendChild(document.createTextNode(title));
      this.popup.className = 'menu-sidebar-item-popup';
    }

    if (!layoutManager.leftSideBarExpanded) {
      document.body.appendChild(this.popup);
      this.popup.style.top = `${element.getBoundingClientRect().top}px`;
    }
  }

  @action
  hidePopup() {
    if (this.popup.parentNode) {
      document.body.removeChild(this.popup);
    }
  }
}
