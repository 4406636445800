import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='menu-sidebar-item mw-100 {{if minor \"sidebar-item-minor\"}}'>\n  <span class='menu-sidebar-item-icon'>\n    <FaIcon @icon={{icon}} @fixedWidth={{true}} />\n  </span>\n  <span\n    class='menu-sidebar-item-text\n      {{if layoutManager.leftSideBarExpanded \"visible\"}}'\n  >\n    {{title}}\n  </span>\n</div>", {"contents":"<div class='menu-sidebar-item mw-100 {{if minor \"sidebar-item-minor\"}}'>\n  <span class='menu-sidebar-item-icon'>\n    <FaIcon @icon={{icon}} @fixedWidth={{true}} />\n  </span>\n  <span\n    class='menu-sidebar-item-text\n      {{if layoutManager.leftSideBarExpanded \"visible\"}}'\n  >\n    {{title}}\n  </span>\n</div>","moduleName":"amber-ui/components/menu-sidebar-link.hbs","parseOptions":{"srcName":"amber-ui/components/menu-sidebar-link.hbs"}});
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MenuSidebarLinkComponent extends Component {
  @service layoutManager;
  @tracked minor = false;
}
