import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{content}}", {"contents":"{{content}}","moduleName":"amber-ui/components/poll/status-tag.hbs","parseOptions":{"srcName":"amber-ui/components/poll/status-tag.hbs"}});
import { FormStatusTagComponent } from 'amber-ui/components/form/status-tag';

const PollStatusTagComponent = FormStatusTagComponent.extend({
  isNoneValue: 'laden...',
  responseCompletedValue: 'Ingevuld',
});

PollStatusTagComponent.reopenClass({
  positionalParams: ['form'],
});

export default PollStatusTagComponent;
