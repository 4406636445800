import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{content}}", {"contents":"{{content}}","moduleName":"amber-ui/components/form/status-tag.hbs","parseOptions":{"srcName":"amber-ui/components/form/status-tag.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export const FormStatusTagComponent = Component.extend({
  form: null,
  tagName: 'span',
  isTag: true,
  isNoneValue: 'Vrij toegankelijk',
  responseCompletedValue: 'Ingeschreven',
  classNameBindings: ['isTag:badge', 'colorIndicatorClass'],
  content: computed(
    'form.{canRespond,currentUserResponseCompleted,respondFrom}',
    'isNoneValue',
    'responseCompletedValue',
    function () {
      if (isNone(this.form)) {
        return this.isNoneValue;
      }

      if (this.form.get('currentUserResponseCompleted')) {
        return this.responseCompletedValue;
      }

      if (this.form.get('canRespond')) {
        return 'Open';
      }

      if (this.form.get('respondFrom') > new Date()) {
        return 'Opent later';
      }

      return 'Gesloten';
    }
  ),
  colorIndicatorClass: computed(
    'form',
    'form.canRespond',
    'form.respondFrom',
    'form.currentUserResponseCompleted',
    function () {
      if (isNone(this.form)) {
        return 'bg-success';
      }

      const formIsOpen = this.form.get('canRespond');
      const userHasResponded = this.form.get('currentUserResponseCompleted');

      if (userHasResponded) {
        return 'bg-success';
      }

      return formIsOpen ? 'bg-info' : 'badge-default';
    }
  ),
});

FormStatusTagComponent.reopenClass({
  positionalParams: ['form'],
});

export default FormStatusTagComponent;
