import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<legend class={{if (not-eq inputLayout 'vertical') 'col-form-legend col-sm-2'}}>\n  {{label}}{{if required ' *'}}\n</legend>\n<div class={{if (not-eq inputLayout 'vertical') 'col-sm-10'}}>\n  {{#each options as |opt|}}\n    <div class='form-check mb-2'>\n      <ModelForm::CheckboxButton\n          @class={{concat 'form-check-input ' inputValidityClass}}\n          @name={{inputIdentifier}}\n          @id={{concat inputIdentifier '-' opt.value}}\n          @value={{opt.value}}\n          @groupValue={{mut (get model property)}}\n          @required={{requiredAndNothingSelected}}\n          @disabled={{disabled}}\n        />\n      <label class='form-check-label' for='{{inputIdentifier}}-{{opt.value}}'>{{opt.label}}</label>\n    </div>\n  {{/each}}\n\n  {{form-control-feedback (get @model.errors property)}}\n</div>", {"contents":"<legend class={{if (not-eq inputLayout 'vertical') 'col-form-legend col-sm-2'}}>\n  {{label}}{{if required ' *'}}\n</legend>\n<div class={{if (not-eq inputLayout 'vertical') 'col-sm-10'}}>\n  {{#each options as |opt|}}\n    <div class='form-check mb-2'>\n      <ModelForm::CheckboxButton\n          @class={{concat 'form-check-input ' inputValidityClass}}\n          @name={{inputIdentifier}}\n          @id={{concat inputIdentifier '-' opt.value}}\n          @value={{opt.value}}\n          @groupValue={{mut (get model property)}}\n          @required={{requiredAndNothingSelected}}\n          @disabled={{disabled}}\n        />\n      <label class='form-check-label' for='{{inputIdentifier}}-{{opt.value}}'>{{opt.label}}</label>\n    </div>\n  {{/each}}\n\n  {{form-control-feedback (get @model.errors property)}}\n</div>","moduleName":"amber-ui/components/model-form/checkbox-group.hbs","parseOptions":{"srcName":"amber-ui/components/model-form/checkbox-group.hbs"}});
import { reads } from '@ember/object/computed';
import ModelFormTextInputComponent from './text-input';

export default ModelFormTextInputComponent.extend({
  tagName: 'fieldset',
  options: null,
  requiredAndNothingSelected: reads('required'),
});
