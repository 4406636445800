import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModelForm::CheckboxGroup\n  @model={{this}}\n  @property='selectedOptionIds'\n  @options={{options}}\n  @inputLayout='vertical'\n  @label={{question.question}}\n  @inputIdentifier={{inputIdentifier}}\n  @required={{question.required}}\n  @requiredAndNothingSelected={{requiredAndNothingSelected}}\n  {{on 'click' (fn this.updateAnswers this.question this.selectedOptionIds)}}\n/>", {"contents":"<ModelForm::CheckboxGroup\n  @model={{this}}\n  @property='selectedOptionIds'\n  @options={{options}}\n  @inputLayout='vertical'\n  @label={{question.question}}\n  @inputIdentifier={{inputIdentifier}}\n  @required={{question.required}}\n  @requiredAndNothingSelected={{requiredAndNothingSelected}}\n  {{on 'click' (fn this.updateAnswers this.question this.selectedOptionIds)}}\n/>","moduleName":"amber-ui/components/form/response/multiple-choice-question.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/multiple-choice-question.hbs"}});
import { mapBy } from '@ember/object/computed';
import { computed } from '@ember/object';
import { ClosedQuestionBaseComponent } from './closed-question';

const MultipleChoiceQuestionComponent = ClosedQuestionBaseComponent.extend({
  answers: null,
  selectedOptionIds: mapBy('answers', 'option.id'),
  requiredAndNothingSelected: computed(
    'question.required',
    'selectedOptionIds.length',
    function () {
      return this.question.required && this.selectedOptionIds.length === 0;
    }
  ),
});

MultipleChoiceQuestionComponent.reopenClass({
  positionalParams: ['question', 'answers'],
});

export default MultipleChoiceQuestionComponent;
