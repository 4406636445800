import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='card tool'>\n  <div class='card-header'>\n    <LinkTo @route='forum'>\n      <h5 class='card-text'>Forum</h5>\n    </LinkTo>\n  </div>\n\n  <ul class='list-group list-group-flush'>\n    {{#each threads as |thread|}}\n      <LinkTo\n        @route='forum.categories.category.threads.thread'\n        @models={{array thread.category.id thread.id}}\n      >\n        <li class='list-group-item'>\n          <span>\n            {{#unless thread.read}}\n              <div class='new-forum-posts status-dot'></div>\n            {{/unless}}\n            {{#unless thread.isOpen}}\n              <FaIcon @icon='lock' />\n            {{/unless}}\n            {{thread.title}}\n          </span>\n        </li>\n      </LinkTo>\n    {{else}}\n      <li class='list-group-item'>\n        Er zijn helaas geen forum topics!\n      </li>\n    {{/each}}\n  </ul>\n</div>", {"contents":"<div class='card tool'>\n  <div class='card-header'>\n    <LinkTo @route='forum'>\n      <h5 class='card-text'>Forum</h5>\n    </LinkTo>\n  </div>\n\n  <ul class='list-group list-group-flush'>\n    {{#each threads as |thread|}}\n      <LinkTo\n        @route='forum.categories.category.threads.thread'\n        @models={{array thread.category.id thread.id}}\n      >\n        <li class='list-group-item'>\n          <span>\n            {{#unless thread.read}}\n              <div class='new-forum-posts status-dot'></div>\n            {{/unless}}\n            {{#unless thread.isOpen}}\n              <FaIcon @icon='lock' />\n            {{/unless}}\n            {{thread.title}}\n          </span>\n        </li>\n      </LinkTo>\n    {{else}}\n      <li class='list-group-item'>\n        Er zijn helaas geen forum topics!\n      </li>\n    {{/each}}\n  </ul>\n</div>","moduleName":"amber-ui/components/tools/forum-posts.hbs","parseOptions":{"srcName":"amber-ui/components/tools/forum-posts.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  store: service(),
  threads: computed(function () {
    return this.store.query('forum/thread', {
      sort: '-updated_at',
      page: { number: '1', size: 7 },
    });
  }),
});
