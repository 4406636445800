import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  class='status-icon text-{{unless thread.read \"primary\" \"white\"}}\n    {{if thread.isOpen \"small\"}}'\n>\n  <FaIcon @icon={{if thread.isOpen 'circle' 'lock'}} />\n</span>", {"contents":"<span\n  class='status-icon text-{{unless thread.read \"primary\" \"white\"}}\n    {{if thread.isOpen \"small\"}}'\n>\n  <FaIcon @icon={{if thread.isOpen 'circle' 'lock'}} />\n</span>","moduleName":"amber-ui/components/forum/status-icon.hbs","parseOptions":{"srcName":"amber-ui/components/forum/status-icon.hbs"}});
import Component from '@ember/component';

export const ForumStatusIconComponent = Component.extend({
  thread: null,
});

ForumStatusIconComponent.reopenClass({
  positionalParams: ['thread'],
});

export default ForumStatusIconComponent;
