import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='alert alert-success'\n  role='alert'\n  data-test-response-completed-alert\n>\n  <div class='d-flex'>\n    <strong class='flex-grow-1 alert-text'>Je bent ingeschreven!</strong>\n    <span class='alert-text'>\n      Deze inschrijflijst\n      {{lowercase (form-opened-label form)}}\n    </span>\n    {{#if form.canRespond}}\n      <LinkTo\n        @route='form.responses.response.destroy'\n        @model={{form.currentUserResponseId}}\n        class='btn btn-secondary'\n      >\n        Uitschrijven\n      </LinkTo>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div\n  class='alert alert-success'\n  role='alert'\n  data-test-response-completed-alert\n>\n  <div class='d-flex'>\n    <strong class='flex-grow-1 alert-text'>Je bent ingeschreven!</strong>\n    <span class='alert-text'>\n      Deze inschrijflijst\n      {{lowercase (form-opened-label form)}}\n    </span>\n    {{#if form.canRespond}}\n      <LinkTo\n        @route='form.responses.response.destroy'\n        @model={{form.currentUserResponseId}}\n        class='btn btn-secondary'\n      >\n        Uitschrijven\n      </LinkTo>\n    {{/if}}\n  </div>\n</div>","moduleName":"amber-ui/components/form/response/response-completed-alert.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/response-completed-alert.hbs"}});
import Component from '@ember/component';

const ResponseCompletedAlert = Component.extend({
  form: null,
});

ResponseCompletedAlert.reopenClass({
  positionalParams: ['form'],
});

export default ResponseCompletedAlert;
