import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='card'>\n  <div class='card-header'>\n    <h5 class='card-text'> Bijbelvers van de dag </h5>\n  </div>\n\n  <div class='card-block pb-2'>\n    {{#each verses as |verse|}}\n      <div class='card-body'>\n        <i class='card-text'> {{verse.content}} </i>\n        <a\n          href='https://www.debijbel.nl/bijbel/BGT/{{verse.reference}}'\n          target='_blank'\n          rel='noopener noreferrer'\n        >\n          <span class='card-text float-end pt-1'> {{verse.reference}} </span>\n        </a>\n        <footer class='blockquote-footer mt-2'>{{verse.copyright}}</footer>\n      </div>\n    {{/each}}\n  </div>\n</div>", {"contents":"<div class='card'>\n  <div class='card-header'>\n    <h5 class='card-text'> Bijbelvers van de dag </h5>\n  </div>\n\n  <div class='card-block pb-2'>\n    {{#each verses as |verse|}}\n      <div class='card-body'>\n        <i class='card-text'> {{verse.content}} </i>\n        <a\n          href='https://www.debijbel.nl/bijbel/BGT/{{verse.reference}}'\n          target='_blank'\n          rel='noopener noreferrer'\n        >\n          <span class='card-text float-end pt-1'> {{verse.reference}} </span>\n        </a>\n        <footer class='blockquote-footer mt-2'>{{verse.copyright}}</footer>\n      </div>\n    {{/each}}\n  </div>\n</div>","moduleName":"amber-ui/components/tools/daily-verse.hbs","parseOptions":{"srcName":"amber-ui/components/tools/daily-verse.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  store: service(),
  verses: computed(function () {
    return this.store.findAll('daily-verse');
  }),
});
