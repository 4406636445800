import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='card tool'>\n  <div class='card-header'>\n    <LinkTo @route='polls'>\n      <h5 class='card-text'>Polls</h5>\n    </LinkTo>\n  </div>\n\n  <ul class='list-group list-group-flush'>\n    {{#each polls as |poll|}}\n      <LinkTo @route='polls.poll' @model={{poll.id}}>\n        <li class='list-group-item'>\n          <div class='d-flex justify-content-between'>\n            <span>\n              {{#if poll.currentUserCanRespond}}\n                <div class='new-forum-posts status-dot'></div>\n              {{/if}}\n              {{poll.question.question}}\n            </span>\n            <span class='float-xs-end'>\n              {{poll.form.amountOfResponses}}\n            </span>\n          </div>\n        </li>\n      </LinkTo>\n    {{else}}\n      <li class='list-group-item'>\n        Er zijn helaas geen recente polls!\n      </li>\n    {{/each}}\n  </ul>\n</div>", {"contents":"<div class='card tool'>\n  <div class='card-header'>\n    <LinkTo @route='polls'>\n      <h5 class='card-text'>Polls</h5>\n    </LinkTo>\n  </div>\n\n  <ul class='list-group list-group-flush'>\n    {{#each polls as |poll|}}\n      <LinkTo @route='polls.poll' @model={{poll.id}}>\n        <li class='list-group-item'>\n          <div class='d-flex justify-content-between'>\n            <span>\n              {{#if poll.currentUserCanRespond}}\n                <div class='new-forum-posts status-dot'></div>\n              {{/if}}\n              {{poll.question.question}}\n            </span>\n            <span class='float-xs-end'>\n              {{poll.form.amountOfResponses}}\n            </span>\n          </div>\n        </li>\n      </LinkTo>\n    {{else}}\n      <li class='list-group-item'>\n        Er zijn helaas geen recente polls!\n      </li>\n    {{/each}}\n  </ul>\n</div>","moduleName":"amber-ui/components/tools/recent-polls.hbs","parseOptions":{"srcName":"amber-ui/components/tools/recent-polls.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  store: service(),
  polls: computed(function () {
    return this.store.query('poll', {
      sort: '-created_at',
      page: { number: '1', size: 5 },
    });
  }),
});
