import { helper } from '@ember/component/helper';

export function convertToUnicode(text) {
  const emojiRegex = /(:\S*:)/g;

  let match;
  while ((match = emojiRegex.exec(text)) != null) {
    if (emojiList[match[0]]) {
      text = text.replace(match[0], emojiList[match[0]]);
    }
  }

  return text;
}

export default helper(convertToUnicode);

// Source: https://raw.githubusercontent.com/paragbhadoria/emoji.json/master/emoji.js
// Source key, value inverted
const emojiList = {
  '*': '*️⃣',
  ':0:': '0️⃣',
  ':100:': '💯',
  ':1234:': '🔢',
  ':1:': '1️⃣',
  ':2:': '2️⃣',
  ':3:': '3️⃣',
  ':4:': '4️⃣',
  ':5:': '5️⃣',
  ':6:': '6️⃣',
  ':7:': '7️⃣',
  ':8:': '8️⃣',
  ':8ball:': '🎱',
  ':9:': '9️⃣',
  ':a:': '🅰️',
  ':ab:': '🆎',
  ':abc:': '🔤',
  ':abcd:': '🔡',
  ':accept:': '🉑',
  ':admission_tickets:': '🎟',
  ':aerial_tramway:': '🚡',
  ':airplane:': '✈️',
  ':airplane_arriving:': '🛬',
  ':airplane_departure:': '🛫',
  ':alarm_clock:': '⏰',
  ':alembic:': '⚗️',
  ':alien:': '👽',
  ':ambulance:': '🚑',
  ':amphora:': '🏺',
  ':anchor:': '⚓️',
  ':angel:': '👼',
  ':angel_dark_skin_tone:': '👼🏿',
  ':angel_light_skin_tone:': '👼🏻',
  ':angel_medium_dark_skin_tone:': '👼🏾',
  ':angel_medium_light_skin_tone:': '👼🏼',
  ':angel_medium_skin_tone:': '👼🏽',
  ':anger:': '💢',
  ':anger_right:': '🗯',
  ':angry:': '😠',
  ':anguished:': '😧',
  ':ant:': '🐜',
  ':apple:': '🍎',
  ':aquarius:': '♒️',
  ':aries:': '♈️',
  ':arrow_backward:': '◀️',
  ':arrow_double_down:': '⏬',
  ':arrow_double_up:': '⏫',
  ':arrow_down:': '⬇️',
  ':arrow_down_small:': '🔽',
  ':arrow_forward:': '▶️',
  ':arrow_heading_down:': '⤵️',
  ':arrow_heading_up:': '⤴️',
  ':arrow_left:': '⬅️',
  ':arrow_lower_left:': '↙️',
  ':arrow_lower_right:': '↘️',
  ':arrow_right:': '➡️',
  ':arrow_right_hook:': '↪️',
  ':arrow_up:': '⬆️',
  ':arrow_up_down:': '↕️',
  ':arrow_up_small:': '🔼',
  ':arrow_upper_left:': '↖️',
  ':arrow_upper_right:': '↗️',
  ':arrows_clockwise:': '🔃',
  ':arrows_counterclockwise:': '🔄',
  ':art:': '🎨',
  ':articulated_lorry:': '🚛',
  ':asterisk:': '*⃣',
  ':astonished:': '😲',
  ':athletic_shoe:': '👟',
  ':atm:': '🏧',
  ':atom:': '⚛',
  ':atom_symbol:': '⚛️',
  ':avocado:': '🥑',
  ':b:': '🅱️',
  ':baby:': '👶',
  ':baby_bottle:': '🍼',
  ':baby_chick:': '🐤',
  ':baby_dark_skin_tone:': '👶🏿',
  ':baby_light_skin_tone:': '👶🏻',
  ':baby_medium_dark_skin_tone:': '👶🏾',
  ':baby_medium_light_skin_tone:': '👶🏼',
  ':baby_medium_skin_tone:': '👶🏽',
  ':baby_symbol:': '🚼',
  ':back:': '🔙',
  ':bacon:': '🥓',
  ':badminton_racquet_and_shuttlecock:': '🏸',
  ':baggage_claim:': '🛄',
  ':baguette_bread:': '🥖',
  ':balloon:': '🎈',
  ':ballot_box:': '🗳',
  ':ballot_box_with_check:': '☑️',
  ':bamboo:': '🎍',
  ':banana:': '🍌',
  ':bangbang:': '‼️',
  ':bank:': '🏦',
  ':bar_chart:': '📊',
  ':barber:': '💈',
  ':baseball:': '⚾️',
  ':basketball:': '🏀',
  ':basketballer:': '⛹',
  ':basketballer_darktone:': '⛹🏿',
  ':basketballer_lt:': '⛹🏻',
  ':basketballer_mediumdarktone:': '⛹🏾',
  ':basketballer_mediumlighttone:': '⛹🏼',
  ':basketballer_mediumtone:': '⛹🏽',
  ':basketballer_woman:': '⛹‍♀',
  ':basketballer_woman_dt:': '⛹🏿‍♀',
  ':basketballer_woman_lt:': '⛹🏻‍♀',
  ':basketballer_woman_mdt:': '⛹🏾‍♀',
  ':basketballer_woman_mlt:': '⛹🏼‍♀',
  ':basketballer_woman_mt:': '⛹🏽‍♀',
  ':bat:': '🦇',
  ':bath:': '🛀',
  ':bath_dark_skin_tone:': '🛀🏿',
  ':bath_light_skin_tone:': '🛀🏻',
  ':bath_medium_dark_skin_tone:': '🛀🏾',
  ':bath_medium_light_skin_tone:': '🛀🏼',
  ':bath_medium_skin_tone:': '🛀🏽',
  ':bathtub:': '🛁',
  ':battery:': '🔋',
  ':beach_umbrella:': '⛱',
  ':beach_with_umbrella:': '🏖',
  ':bear:': '🐻',
  ':bed:': '🛏',
  ':bee:': '🐝',
  ':beer:': '🍺',
  ':beers:': '🍻',
  ':beetle:': '🐞',
  ':beginner:': '🔰',
  ':bell:': '🔔',
  ':bellhop_bell:': '🛎',
  ':bento:': '🍱',
  ':bicyclist:': '🚴',
  ':bicyclist_dark_skin_tone:': '🚴🏿',
  ':bicyclist_light_skin_tone:': '🚴🏻',
  ':bicyclist_medium_dark_skin_tone:': '🚴🏾',
  ':bicyclist_medium_light_skin_tone:': '🚴🏼',
  ':bicyclist_medium_skin_tone:': '🚴🏽',
  ':bike:': '🚲',
  ':bikini:': '👙',
  ':biohazard:': '☣',
  ':biohazard_sign:': '☣️',
  ':bird:': '🐦',
  ':birthday:': '🎂',
  ':black_circle:': '⚫️',
  ':black_circle_for_record:': '⏺',
  ':black_heart:': '🖤',
  ':black_joker:': '🃏',
  ':black_large_square:': '⬛️',
  ':black_medium_small_square:': '◾️',
  ':black_medium_square:': '◼️',
  ':black_nib:': '✒️',
  ':black_small_square:': '▪️',
  ':black_square_button:': '🔲',
  ':black_square_for_stop:': '⏹',
  ':blond_hair:': '👱',
  ':blond_hair_dark_skin_tone:': '👱🏿',
  ':blond_hair_light_skin_tone:': '👱🏻',
  ':blond_hair_medium_dark_skin_tone:': '👱🏾',
  ':blond_hair_medium_light_skin_tone:': '👱🏼',
  ':blond_hair_medium_skin_tone:': '👱🏽',
  ':blossom:': '🌼',
  ':blowfish:': '🐡',
  ':blue_book:': '📘',
  ':blue_car:': '🚙',
  ':blue_circle:': '🔵',
  ':blue_heart:': '💙',
  ':blush:': '😊',
  ':boar:': '🐗',
  ':bomb:': '💣',
  ':book:': '📖',
  ':bookmark:': '🔖',
  ':bookmark_tabs:': '📑',
  ':books:': '📚',
  ':boom:': '💥',
  ':boot:': '👢',
  ':bouquet:': '💐',
  ':bow:': '🙇',
  ':bow_and_arrow:': '🏹',
  ':bow_dark_skin_tone:': '🙇🏿',
  ':bow_light_skin_tone:': '🙇🏻',
  ':bow_medium_dark_skin_tone:': '🙇🏾',
  ':bow_medium_light_skin_tone:': '🙇🏼',
  ':bow_medium_skin_tone:': '🙇🏽',
  ':bowling:': '🎳',
  ':boxing_glove:': '🥊',
  ':boy:': '👦',
  ':boy_dark_skin_tone:': '👦🏿',
  ':boy_light_skin_tone:': '👦🏻',
  ':boy_medium_dark_skin_tone:': '👦🏾',
  ':boy_medium_light_skin_tone:': '👦🏼',
  ':boy_medium_skin_tone:': '👦🏽',
  ':bread:': '🍞',
  ':bride_with_veil:': '👰',
  ':bride_with_veil_dark_skin_tone:': '👰🏿',
  ':bride_with_veil_light_skin_tone:': '👰🏻',
  ':bride_with_veil_medium_dark_skin_tone:': '👰🏾',
  ':bride_with_veil_medium_light_skin_tone:': '👰🏼',
  ':bride_with_veil_medium_skin_tone:': '👰🏽',
  ':bridge_at_night:': '🌉',
  ':briefcase:': '💼',
  ':broken_heart:': '💔',
  ':bug:': '🐛',
  ':building_construction:': '🏗',
  ':bulb:': '💡',
  ':bullettrain_front:': '🚅',
  ':bullettrain_side:': '🚄',
  ':burrito:': '🌯',
  ':bus:': '🚌',
  ':busstop:': '🚏',
  ':bust_in_silhouette:': '👤',
  ':busts_in_silhouette:': '👥',
  ':butterfly:': '🦋',
  ':cactus:': '🌵',
  ':cake:': '🍰',
  ':calendar:': '📆',
  ':call_me_hand:': '🤙',
  ':calling:': '📲',
  ':camel:': '🐫',
  ':camera:': '📷',
  ':camera_with_flash:': '📸',
  ':camping:': '🏕',
  ':cancer:': '♋️',
  ':candle:': '🕯',
  ':candy:': '🍬',
  ':canoe:': '🛶',
  ':capital_abcd:': '🔠',
  ':capricorn:': '♑️',
  ':card_file_box:': '🗃',
  ':card_index:': '📇',
  ':card_index_dividers:': '🗂',
  ':carousel_horse:': '🎠',
  ':carrot:': '🥕',
  ':cat2:': '🐈',
  ':cat:': '🐱',
  ':cd:': '💿',
  ':chains:': '⛓',
  ':champagne:': '🍾',
  ':chart:': '💹',
  ':chart_with_downwards_trend:': '📉',
  ':chart_with_upwards_trend:': '📈',
  ':checkered_flag:': '🏁',
  ':cheese_wedge:': '🧀',
  ':cherries:': '🍒',
  ':cherry_blossom:': '🌸',
  ':chestnut:': '🌰',
  ':chicken:': '🐔',
  ':children_crossing:': '🚸',
  ':chipmunk:': '🐿',
  ':chocolate_bar:': '🍫',
  ':christmas_tree:': '🎄',
  ':church:': '⛪️',
  ':cinema:': '🎦',
  ':circus_tent:': '🎪',
  ':city_sunset:': '🌆',
  ':cityscape:': '🏙',
  ':cl:': '🆑',
  ':clap:': '👏',
  ':clap_dark_skin_tone:': '👏🏿',
  ':clap_light_skin_tone:': '👏🏻',
  ':clap_medium_dark_skin_tone:': '👏🏾',
  ':clap_medium_light_skin_tone:': '👏🏼',
  ':clap_medium_skin_tone:': '👏🏽',
  ':clapper:': '🎬',
  ':classical_building:': '🏛',
  ':clinking_glasses:': '🥂',
  ':clipboard:': '📋',
  ':clock1030:': '🕥',
  ':clock10:': '🕙',
  ':clock1130:': '🕦',
  ':clock11:': '🕚',
  ':clock1230:': '🕧',
  ':clock12:': '🕛',
  ':clock130:': '🕜',
  ':clock1:': '🕐',
  ':clock230:': '🕝',
  ':clock2:': '🕑',
  ':clock330:': '🕞',
  ':clock3:': '🕒',
  ':clock430:': '🕟',
  ':clock4:': '🕓',
  ':clock530:': '🕠',
  ':clock5:': '🕔',
  ':clock630:': '🕡',
  ':clock6:': '🕕',
  ':clock730:': '🕢',
  ':clock7:': '🕖',
  ':clock830:': '🕣',
  ':clock8:': '🕗',
  ':clock930:': '🕤',
  ':clock9:': '🕘',
  ':closed_book:': '📕',
  ':closed_lock_with_key:': '🔐',
  ':closed_umbrella:': '🌂',
  ':cloud:': '☁️',
  ':clown_face:': '🤡',
  ':clubs:': '♣',
  ':cocktail:': '🍸',
  ':coffee:': '☕️',
  ':coffin:': '⚰️',
  ':cold_sweat:': '😰',
  ':comet:': '☄️',
  ':compression:': '🗜',
  ':computer:': '💻',
  ':confetti_ball:': '🎊',
  ':confounded:': '😖',
  ':confused:': '😕',
  ':congratulations:': '㊗️',
  ':construction:': '🚧',
  ':construction_worker:': '👷',
  ':construction_worker_dark_skin_tone:': '👷🏿',
  ':construction_worker_light_skin_tone:': '👷🏻',
  ':construction_worker_medium_dark_skin_tone:': '👷🏾',
  ':construction_worker_medium_light_skin_tone:': '👷🏼',
  ':construction_worker_medium_skin_tone:': '👷🏽',
  ':control_knobs:': '🎛',
  ':convenience_store:': '🏪',
  ':cookie:': '🍪',
  ':cooking:': '🍳',
  ':cool:': '🆒',
  ':cop:': '👮',
  ':cop_dark_skin_tone:': '👮🏿',
  ':cop_light_skin_tone:': '👮🏻',
  ':cop_medium_dark_skin_tone:': '👮🏾',
  ':cop_medium_light_skin_tone:': '👮🏼',
  ':cop_medium_skin_tone:': '👮🏽',
  ':copyright:': '©',
  ':corn:': '🌽',
  ':couch_and_lamp:': '🛋',
  ':couple:': '👫',
  ':couple_man_kiss:': '👨‍❤️‍💋‍👨',
  ':couple_with_heart:': '👩‍❤️‍👨',
  ':couple_woman_kiss:': '👩‍❤️‍💋‍👩',
  ':couplekiss:': '👩‍❤️‍💋‍👨',
  ':cow2:': '🐄',
  ':cow:': '🐮',
  ':crab:': '🦀',
  ':credit_card:': '💳',
  ':crescent_moon:': '🌙',
  ':cricket_bat_and_ball:': '🏏',
  ':crocodile:': '🐊',
  ':croissant:': '🥐',
  ':cross:': '✝️',
  ':crossed_flags:': '🎌',
  ':crossed_swords:': '⚔️',
  ':crown:': '👑',
  ':cry:': '😢',
  ':crying_cat_face:': '😿',
  ':crystal_ball:': '🔮',
  ':cucumber:': '🥒',
  ':cupid:': '💘',
  ':curly_loop:': '➰',
  ':currency_exchange:': '💱',
  ':curry:': '🍛',
  ':custard:': '🍮',
  ':customs:': '🛃',
  ':cyclone:': '🌀',
  ':dagger_knife:': '🗡',
  ':dancer:': '💃',
  ':dancer_dark_skin_tone:': '💃🏿',
  ':dancer_light_skin_tone:': '💃🏻',
  ':dancer_medium_dark_skin_tone:': '💃🏾',
  ':dancer_medium_light_skin_tone:': '💃🏼',
  ':dancer_medium_skin_tone:': '💃🏽',
  ':dancers:': '👯',
  ':dango:': '🍡',
  ':dark_sunglasses:': '🕶',
  ':dart:': '🎯',
  ':dash:': '💨',
  ':date:': '📅',
  ':deciduous_tree:': '🌳',
  ':deer:': '🦌',
  ':department_store:': '🏬',
  ':derelict_house_building:': '🏚',
  ':desert:': '🏜',
  ':desert_island:': '🏝',
  ':desktop_computer:': '🖥',
  ':detective:': '🕵',
  ':detective_dark_skin_tone:': '🕵🏿',
  ':detective_light_skin_tone:': '🕵🏻',
  ':detective_medium_dark_skin_tone:': '🕵🏾',
  ':detective_medium_light_skin_tone:': '🕵🏼',
  ':detective_medium_skin_tone:': '🕵🏽',
  ':diamond_shape_with_a_dot_inside:': '💠',
  ':diamonds:': '♦',
  ':disappointed:': '😞',
  ':disappointed_relieved:': '😥',
  ':dizzy:': '💫',
  ':dizzy_face:': '😵',
  ':do_not_litter:': '🚯',
  ':dog2:': '🐕',
  ':dog:': '🐶',
  ':dollar:': '💵',
  ':dolls:': '🎎',
  ':dolphin:': '🐬',
  ':door:': '🚪',
  ':double_vertical_bar:': '⏸',
  ':doughnut:': '🍩',
  ':dove_of_peace:': '🕊',
  ':dragon:': '🐉',
  ':dragon_face:': '🐲',
  ':dress:': '👗',
  ':dromedary_camel:': '🐪',
  ':drooling_face:': '🤤',
  ':droplet:': '💧',
  ':drum:': '🥁',
  ':duck:': '🦆',
  ':dvd:': '📀',
  ':e:': '🇪',
  ':e_mail:': '📧',
  ':eagle:': '🦅',
  ':ear:': '👂',
  ':ear_dark_skin_tone:': '👂🏿',
  ':ear_light_skin_tone:': '👂🏻',
  ':ear_medium_dark_skin_tone:': '👂🏾',
  ':ear_medium_light_skin_tone:': '👂🏼',
  ':ear_medium_skin_tone:': '👂🏽',
  ':ear_of_rice:': '🌾',
  ':earth_africa:': '🌍',
  ':earth_americas:': '🌎',
  ':earth_asia:': '🌏',
  ':egg:': '🥚',
  ':eggplant:': '🍆',
  ':eight:': '8⃣',
  ':eight_pointed_black_star:': '✴️',
  ':eight_spoked_asterisk:': '✳️',
  ':eject:': '⏏',
  ':electric_plug:': '🔌',
  ':elephant:': '🐘',
  ':end:': '🔚',
  ':envelope:': '✉️',
  ':envelope_with_arrow:': '📩',
  ':euro:': '💶',
  ':european_castle:': '🏰',
  ':european_post_office:': '🏤',
  ':evergreen_tree:': '🌲',
  ':exclamation:': '❗',
  ':expressionless:': '😑',
  ':eye:': '👁',
  ':eye_in_speech_bubble:': '👁️‍🗨️',
  ':eye_speachbubble:': '👁‍🗨',
  ':eyeglasses:': '👓',
  ':eyes:': '👀',
  ':face_palm:': '🤦',
  ':face_with_cowboy_hat:': '🤠',
  ':face_with_head_bandage:': '🤕',
  ':face_with_thermometer:': '🤒',
  ':factory:': '🏭',
  ':fallen_leaf:': '🍂',
  ':family:': '👨‍👩‍👦',
  ':family_children:': '👨‍👩‍👧‍👦',
  ':family_fathers_children:': '👨‍👨‍👧‍👦',
  ':family_fathers_one_boy:': '👨‍👨‍👦',
  ':family_fathers_one_girl:': '👨‍👨‍👧',
  ':family_fathers_two_boys:': '👨‍👨‍👦‍👦',
  ':family_fathers_two_girls:': '👨‍👨‍👧‍👧',
  ':family_mothers_children:': '👩‍👩‍👧‍👦',
  ':family_mothers_one_boy:': '👩‍👩‍👦',
  ':family_mothers_one_girl:': '👩‍👩‍👧',
  ':family_mothers_two_boys:': '👩‍👩‍👦‍👦',
  ':family_mothers_two_girls:': '👩‍👩‍👧‍👧',
  ':family_one_girl:': '👨‍👩‍👧',
  ':family_two_boys:': '👨‍👩‍👦‍👦',
  ':family_two_girls:': '👨‍👩‍👧‍👧',
  ':fast_forward:': '⏩',
  ':fax:': '📠',
  ':fearful:': '😨',
  ':feet:': '🐾',
  ':female_artist:': '👩‍🎨',
  ':female_astronaut:': '👩‍🚀',
  ':female_cook:': '👩‍🍳',
  ':female_doctor:': '👩‍⚕️',
  ':female_factory_worker:': '👩‍🏭',
  ':female_farmer:': '👩‍🌾',
  ':female_firefighter:': '👩‍🚒',
  ':female_judge:': '👩‍⚖️',
  ':female_mechanic:': '👩‍🔧',
  ':female_office_worker:': '👩‍💼',
  ':female_pilot:': '👩‍✈️',
  ':female_scientist:': '👩‍🔬',
  ':female_sign:': '♀',
  ':female_singer:': '👩‍🎤',
  ':female_student:': '👩‍🎓',
  ':female_teacher:': '👩‍🏫',
  ':female_technologist:': '👩‍💻',
  ':fencer:': '🤺',
  ':ferris_wheel:': '🎡',
  ':ferry:': '⛴',
  ':field_hockey_stick_and_ball:': '🏑',
  ':file_cabinet:': '🗄',
  ':file_folder:': '📁',
  ':film_frames:': '🎞',
  ':film_projector:': '📽',
  ':fire:': '🔥',
  ':fire_engine:': '🚒',
  ':fireworks:': '🎆',
  ':first_place_medal:': '🥇',
  ':first_quarter_moon:': '🌓',
  ':first_quarter_moon_with_face:': '🌛',
  ':fish:': '🐟',
  ':fish_cake:': '🍥',
  ':fishing_pole_and_fish:': '🎣',
  ':fist:': '✊',
  ':fist_dark_skin_tone:': '✊🏿',
  ':fist_light_skin_tone:': '✊🏻',
  ':fist_medium_dark_skin_tone:': '✊🏾',
  ':fist_medium_light_skin_tone:': '✊🏼',
  ':fist_medium_skin_tone:': '✊🏽',
  ':five:': '5⃣',
  ':flag_ac:': '🇦🇨',
  ':flag_ad:': '🇦🇩',
  ':flag_ae:': '🇦🇪',
  ':flag_af:': '🇦🇫',
  ':flag_ag:': '🇦🇬',
  ':flag_ai:': '🇦🇮',
  ':flag_al:': '🇦🇱',
  ':flag_am:': '🇦🇲',
  ':flag_ao:': '🇦🇴',
  ':flag_aq:': '🇦🇶',
  ':flag_ar:': '🇦🇷',
  ':flag_as:': '🇦🇸',
  ':flag_at:': '🇦🇹',
  ':flag_au:': '🇦🇺',
  ':flag_aw:': '🇦🇼',
  ':flag_ax:': '🇦🇽',
  ':flag_az:': '🇦🇿',
  ':flag_ba:': '🇧🇦',
  ':flag_bb:': '🇧🇧',
  ':flag_bd:': '🇧🇩',
  ':flag_be:': '🇧🇪',
  ':flag_bf:': '🇧🇫',
  ':flag_bg:': '🇧🇬',
  ':flag_bh:': '🇧🇭',
  ':flag_bi:': '🇧🇮',
  ':flag_bj:': '🇧🇯',
  ':flag_bl:': '🇧🇱',
  ':flag_black:': '🏴',
  ':flag_bm:': '🇧🇲',
  ':flag_bn:': '🇧🇳',
  ':flag_bo:': '🇧🇴',
  ':flag_bq:': '🇧🇶',
  ':flag_br:': '🇧🇷',
  ':flag_bs:': '🇧🇸',
  ':flag_bt:': '🇧🇹',
  ':flag_bv:': '🇧🇻',
  ':flag_bw:': '🇧🇼',
  ':flag_by:': '🇧🇾',
  ':flag_bz:': '🇧🇿',
  ':flag_ca:': '🇨🇦',
  ':flag_cc:': '🇨🇨',
  ':flag_cd:': '🇨🇩',
  ':flag_cf:': '🇨🇫',
  ':flag_cg:': '🇨🇬',
  ':flag_ch:': '🇨🇭',
  ':flag_ci:': '🇨🇮',
  ':flag_ck:': '🇨🇰',
  ':flag_cl:': '🇨🇱',
  ':flag_cm:': '🇨🇲',
  ':flag_cn:': '🇨🇳',
  ':flag_co:': '🇨🇴',
  ':flag_cp:': '🇨🇵',
  ':flag_cr:': '🇨🇷',
  ':flag_cu:': '🇨🇺',
  ':flag_cv:': '🇨🇻',
  ':flag_cw:': '🇨🇼',
  ':flag_cx:': '🇨🇽',
  ':flag_cy:': '🇨🇾',
  ':flag_cz:': '🇨🇿',
  ':flag_de:': '🇩🇪',
  ':flag_dg:': '🇩🇬',
  ':flag_dj:': '🇩🇯',
  ':flag_dk:': '🇩🇰',
  ':flag_dm:': '🇩🇲',
  ':flag_do:': '🇩🇴',
  ':flag_dz:': '🇩🇿',
  ':flag_ea:': '🇪🇦',
  ':flag_ec:': '🇪🇨',
  ':flag_ee:': '🇪🇪',
  ':flag_eg:': '🇪🇬',
  ':flag_eh:': '🇪🇭',
  ':flag_er:': '🇪🇷',
  ':flag_es:': '🇪🇸',
  ':flag_et:': '🇪🇹',
  ':flag_eu:': '🇪🇺',
  ':flag_fi:': '🇫🇮',
  ':flag_fj:': '🇫🇯',
  ':flag_fk:': '🇫🇰',
  ':flag_fm:': '🇫🇲',
  ':flag_fo:': '🇫🇴',
  ':flag_fr:': '🇫🇷',
  ':flag_ga:': '🇬🇦',
  ':flag_gb:': '🇬🇧',
  ':flag_gd:': '🇬🇩',
  ':flag_ge:': '🇬🇪',
  ':flag_gf:': '🇬🇫',
  ':flag_gg:': '🇬🇬',
  ':flag_gh:': '🇬🇭',
  ':flag_gi:': '🇬🇮',
  ':flag_gl:': '🇬🇱',
  ':flag_gm:': '🇬🇲',
  ':flag_gn:': '🇬🇳',
  ':flag_gp:': '🇬🇵',
  ':flag_gq:': '🇬🇶',
  ':flag_gr:': '🇬🇷',
  ':flag_gs:': '🇬🇸',
  ':flag_gt:': '🇬🇹',
  ':flag_gu:': '🇬🇺',
  ':flag_gw:': '🇬🇼',
  ':flag_gy:': '🇬🇾',
  ':flag_hk:': '🇭🇰',
  ':flag_hm:': '🇭🇲',
  ':flag_hn:': '🇭🇳',
  ':flag_hr:': '🇭🇷',
  ':flag_ht:': '🇭🇹',
  ':flag_hu:': '🇭🇺',
  ':flag_ic:': '🇮🇨',
  ':flag_id:': '🇮🇩',
  ':flag_ie:': '🇮🇪',
  ':flag_il:': '🇮🇱',
  ':flag_im:': '🇮🇲',
  ':flag_in:': '🇮🇳',
  ':flag_io:': '🇮🇴',
  ':flag_iq:': '🇮🇶',
  ':flag_ir:': '🇮🇷',
  ':flag_is:': '🇮🇸',
  ':flag_it:': '🇮🇹',
  ':flag_je:': '🇯🇪',
  ':flag_jm:': '🇯🇲',
  ':flag_jo:': '🇯🇴',
  ':flag_jp:': '🇯🇵',
  ':flag_ke:': '🇰🇪',
  ':flag_kg:': '🇰🇬',
  ':flag_kh:': '🇰🇭',
  ':flag_ki:': '🇰🇮',
  ':flag_km:': '🇰🇲',
  ':flag_kn:': '🇰🇳',
  ':flag_kp:': '🇰🇵',
  ':flag_kr:': '🇰🇷',
  ':flag_kw:': '🇰🇼',
  ':flag_ky:': '🇰🇾',
  ':flag_kz:': '🇰🇿',
  ':flag_la:': '🇱🇦',
  ':flag_lb:': '🇱🇧',
  ':flag_lc:': '🇱🇨',
  ':flag_li:': '🇱🇮',
  ':flag_lk:': '🇱🇰',
  ':flag_lr:': '🇱🇷',
  ':flag_ls:': '🇱🇸',
  ':flag_lt:': '🇱🇹',
  ':flag_lu:': '🇱🇺',
  ':flag_lv:': '🇱🇻',
  ':flag_ly:': '🇱🇾',
  ':flag_ma:': '🇲🇦',
  ':flag_mc:': '🇲🇨',
  ':flag_md:': '🇲🇩',
  ':flag_me:': '🇲🇪',
  ':flag_mf:': '🇲🇫',
  ':flag_mg:': '🇲🇬',
  ':flag_mh:': '🇲🇭',
  ':flag_mk:': '🇲🇰',
  ':flag_ml:': '🇲🇱',
  ':flag_mm:': '🇲🇲',
  ':flag_mn:': '🇲🇳',
  ':flag_mo:': '🇲🇴',
  ':flag_mp:': '🇲🇵',
  ':flag_mq:': '🇲🇶',
  ':flag_mr:': '🇲🇷',
  ':flag_ms:': '🇲🇸',
  ':flag_mt:': '🇲🇹',
  ':flag_mu:': '🇲🇺',
  ':flag_mv:': '🇲🇻',
  ':flag_mw:': '🇲🇼',
  ':flag_mx:': '🇲🇽',
  ':flag_my:': '🇲🇾',
  ':flag_mz:': '🇲🇿',
  ':flag_na:': '🇳🇦',
  ':flag_nc:': '🇳🇨',
  ':flag_ne:': '🇳🇪',
  ':flag_nf:': '🇳🇫',
  ':flag_ng:': '🇳🇬',
  ':flag_ni:': '🇳🇮',
  ':flag_nl:': '🇳🇱',
  ':flag_no:': '🇳🇴',
  ':flag_np:': '🇳🇵',
  ':flag_nr:': '🇳🇷',
  ':flag_nu:': '🇳🇺',
  ':flag_nz:': '🇳🇿',
  ':flag_om:': '🇴🇲',
  ':flag_pa:': '🇵🇦',
  ':flag_pe:': '🇵🇪',
  ':flag_pf:': '🇵🇫',
  ':flag_pg:': '🇵🇬',
  ':flag_ph:': '🇵🇭',
  ':flag_pk:': '🇵🇰',
  ':flag_pl:': '🇵🇱',
  ':flag_pm:': '🇵🇲',
  ':flag_pn:': '🇵🇳',
  ':flag_pr:': '🇵🇷',
  ':flag_ps:': '🇵🇸',
  ':flag_pt:': '🇵🇹',
  ':flag_pw:': '🇵🇼',
  ':flag_py:': '🇵🇾',
  ':flag_qa:': '🇶🇦',
  ':flag_re:': '🇷🇪',
  ':flag_ro:': '🇷🇴',
  ':flag_rs:': '🇷🇸',
  ':flag_ru:': '🇷🇺',
  ':flag_rw:': '🇷🇼',
  ':flag_sa:': '🇸🇦',
  ':flag_sb:': '🇸🇧',
  ':flag_sc:': '🇸🇨',
  ':flag_sd:': '🇸🇩',
  ':flag_se:': '🇸🇪',
  ':flag_sg:': '🇸🇬',
  ':flag_sh:': '🇸🇭',
  ':flag_si:': '🇸🇮',
  ':flag_sj:': '🇸🇯',
  ':flag_sk:': '🇸🇰',
  ':flag_sl:': '🇸🇱',
  ':flag_sm:': '🇸🇲',
  ':flag_sn:': '🇸🇳',
  ':flag_so:': '🇸🇴',
  ':flag_sr:': '🇸🇷',
  ':flag_ss:': '🇸🇸',
  ':flag_st:': '🇸🇹',
  ':flag_sv:': '🇸🇻',
  ':flag_sx:': '🇸🇽',
  ':flag_sy:': '🇸🇾',
  ':flag_sz:': '🇸🇿',
  ':flag_ta:': '🇹🇦',
  ':flag_tc:': '🇹🇨',
  ':flag_td:': '🇹🇩',
  ':flag_tf:': '🇹🇫',
  ':flag_tg:': '🇹🇬',
  ':flag_th:': '🇹🇭',
  ':flag_tj:': '🇹🇯',
  ':flag_tk:': '🇹🇰',
  ':flag_tl:': '🇹🇱',
  ':flag_tm:': '🇹🇲',
  ':flag_tn:': '🇹🇳',
  ':flag_to:': '🇹🇴',
  ':flag_tr:': '🇹🇷',
  ':flag_tt:': '🇹🇹',
  ':flag_tv:': '🇹🇻',
  ':flag_tw:': '🇹🇼',
  ':flag_tz:': '🇹🇿',
  ':flag_ua:': '🇺🇦',
  ':flag_ug:': '🇺🇬',
  ':flag_um:': '🇺🇲',
  ':flag_un:': '🇺🇳',
  ':flag_us:': '🇺🇸',
  ':flag_uy:': '🇺🇾',
  ':flag_uz:': '🇺🇿',
  ':flag_va:': '🇻🇦',
  ':flag_vc:': '🇻🇨',
  ':flag_ve:': '🇻🇪',
  ':flag_vg:': '🇻🇬',
  ':flag_vi:': '🇻🇮',
  ':flag_vn:': '🇻🇳',
  ':flag_vu:': '🇻🇺',
  ':flag_wf:': '🇼🇫',
  ':flag_white:': '🏳',
  ':flag_ws:': '🇼🇸',
  ':flag_xk:': '🇽🇰',
  ':flag_ye:': '🇾🇪',
  ':flag_yt:': '🇾🇹',
  ':flag_za:': '🇿🇦',
  ':flag_zm:': '🇿🇲',
  ':flag_zw:': '🇿🇼',
  ':flags:': '🎏',
  ':flashlight:': '🔦',
  ':fleur_de_lis:': '⚜️',
  ':floppy_disk:': '💾',
  ':flower_playing_cards:': '🎴',
  ':flushed:': '😳',
  ':fog:': '🌫',
  ':foggy:': '🌁',
  ':football:': '🏈',
  ':footprints:': '👣',
  ':fork_and_knife:': '🍴',
  ':fountain:': '⛲️',
  ':four:': '4⃣',
  ':four_leaf_clover:': '🍀',
  ':fox_face:': '🦊',
  ':frame_with_picture:': '🖼',
  ':free:': '🆓',
  ':fried_shrimp:': '🍤',
  ':fries:': '🍟',
  ':frog:': '🐸',
  ':frowning:': '😦',
  ':fuelpump:': '⛽️',
  ':full_moon:': '🌕',
  ':full_moon_with_face:': '🌝',
  ':funeral_urn:': '⚱️',
  ':game_die:': '🎲',
  ':gear:': '⚙️',
  ':gem:': '💎',
  ':gemini:': '♊️',
  ':ghost:': '👻',
  ':gift:': '🎁',
  ':gift_heart:': '💝',
  ':girl:': '👧',
  ':girl_dark_skin_tone:': '👧🏿',
  ':girl_girl_love:': '👩‍❤️‍👩',
  ':girl_light_skin_tone:': '👧🏻',
  ':girl_medium_dark_skin_tone:': '👧🏾',
  ':girl_medium_light_skin_tone:': '👧🏼',
  ':girl_medium_skin_tone:': '👧🏽',
  ':glass_of_milk:': '🥛',
  ':globe_with_meridians:': '🌐',
  ':goal_net:': '🥅',
  ':goat:': '🐐',
  ':golf:': '⛳️',
  ':golfer:': '🏌️‍♂️',
  ':golfer_woman:': '🏌‍♀',
  ':gorilla:': '🦍',
  ':grapes:': '🍇',
  ':green_apple:': '🍏',
  ':green_book:': '📗',
  ':green_heart:': '💚',
  ':green_salad:': '🥗',
  ':grey_exclamation:': '❕',
  ':grey_question:': '❔',
  ':grimacing:': '😬',
  ':grin:': '😁',
  ':grinning:': '😀',
  ':guardsman:': '💂',
  ':guardsman_dark_skin_tone:': '💂🏿',
  ':guardsman_light_skin_tone:': '💂🏻',
  ':guardsman_medium_dark_skin_tone:': '💂🏾',
  ':guardsman_medium_light_skin_tone:': '💂🏼',
  ':guardsman_medium_skin_tone:': '💂🏽',
  ':guitar:': '🎸',
  ':gun:': '🔫',
  ':haircut:': '💇',
  ':haircut_dark_skin_tone:': '💇🏿',
  ':haircut_light_skin_tone:': '💇🏻',
  ':haircut_medium_dark_skin_tone:': '💇🏾',
  ':haircut_medium_light_skin_tone:': '💇🏼',
  ':haircut_medium_skin_tone:': '💇🏽',
  ':hamburger:': '🍔',
  ':hammer:': '🔨',
  ':hammer_and_pick:': '⚒',
  ':hammer_and_wrench:': '🛠',
  ':hamster:': '🐹',
  ':hand_with_index_and_middle_fingers_crossed:': '🤞',
  ':handbag:': '👜',
  ':handball:': '🤾',
  ':handshake:': '🤝',
  ':hash:': '#️⃣',
  ':hatched_chick:': '🐥',
  ':hatching_chick:': '🐣',
  ':headphones:': '🎧',
  ':hear_no_evil:': '🙉',
  ':heart:': '❤️',
  ':heart_decoration:': '💟',
  ':heart_exclamation:': '❣️',
  ':heart_eyes:': '😍',
  ':heart_eyes_cat:': '😻',
  ':heartbeat:': '💓',
  ':heartpulse:': '💗',
  ':hearts:': '♥',
  ':heavy_check_mark:': '✔️',
  ':heavy_division_sign:': '➗',
  ':heavy_dollar_sign:': '💲',
  ':heavy_exclamation_mark:': '❗️',
  ':heavy_minus_sign:': '➖',
  ':heavy_multiplication_x:': '✖️',
  ':heavy_plus_sign:': '➕',
  ':helicopter:': '🚁',
  ':helmet_with_white_cross': '⛑',
  ':herb:': '🌿',
  ':hibiscus:': '🌺',
  ':high_brightness:': '🔆',
  ':high_heel:': '👠',
  ':hole:': '🕳',
  ':honey_pot:': '🍯',
  ':horse:': '🐴',
  ':horse_racing:': '🏇',
  ':hospital:': '🏥',
  ':hot_pepper:': '🌶',
  ':hotdog:': '🌭',
  ':hotel:': '🏨',
  ':hotsprings:': '♨️',
  ':hourglass:': '⌛️',
  ':hourglass_flowing_sand:': '⏳',
  ':house:': '🏠',
  ':house_buildings:': '🏘',
  ':house_with_garden:': '🏡',
  ':hugging:': '🤗',
  ':hushed:': '😯',
  ':ice_cream:': '🍨',
  ':ice_hockey_stick_and_puck:': '🏒',
  ':ice_skate:': '⛸',
  ':icecream:': '🍦',
  ':id:': '🆔',
  ':ideograph_advantage:': '🉐',
  ':imp:': '👿',
  ':inbox_tray:': '📥',
  ':incoming_envelope:': '📨',
  ':information_desk_person:': '💁',
  ':information_desk_person_dark_skin_tone:': '💁🏿',
  ':information_desk_person_light_skin_tone:': '💁🏻',
  ':information_desk_person_medium_dark_skin_tone:': '💁🏾',
  ':information_desk_person_medium_light_skin_tone:': '💁🏼',
  ':information_desk_person_medium_skin_tone:': '💁🏽',
  ':information_source:': 'ℹ️',
  ':innocent:': '😇',
  ':interrobang:': '⁉️',
  ':iphone:': '📱',
  ':izakaya_lantern:': '🏮',
  ':jack_o_lantern:': '🎃',
  ':japan:': '🗾',
  ':japanese_castle:': '🏯',
  ':japanese_goblin:': '👺',
  ':japanese_ogre:': '👹',
  ':jeans:': '👖',
  ':joy:': '😂',
  ':joy_cat:': '😹',
  ':joystick:': '🕹',
  ':juggling:': '🤹',
  ':kaaba:': '🕋',
  ':key:': '🔑',
  ':keyboard:': '⌨️',
  ':keycap_ten:': '🔟',
  ':kimono:': '👘',
  ':kiss:': '💋',
  ':kissing:': '😗',
  ':kissing_cat:': '😽',
  ':kissing_closed_eyes:': '😚',
  ':kissing_heart:': '😘',
  ':kissing_smiling_eyes:': '😙',
  ':kiwifruit:': '🥝',
  ':knife:': '🔪',
  ':knife_fork_plate:': '🍽',
  ':koala:': '🐨',
  ':koko:': '🈁',
  ':label:': '🏷',
  ':large_blue_diamond:': '🔷',
  ':large_orange_diamond:': '🔶',
  ':last_quarter_moon:': '🌗',
  ':last_quarter_moon_with_face:': '🌜',
  ':laughing:': '😆',
  ':leaves:': '🍃',
  ':ledger:': '📒',
  ':left_facing_fist:': '🤛',
  ':left_luggage:': '🛅',
  ':left_right_arrow:': '↔',
  ':leftwards_arrow_with_hook:': '↩️',
  ':lemon:': '🍋',
  ':leo:': '♌️',
  ':leopard:': '🐆',
  ':level_slider:': '🎚',
  ':libra:': '♎️',
  ':light_rail:': '🚈',
  ':lightning_cloud:': '🌩',
  ':link:': '🔗',
  ':linked_paperclips:': '🖇',
  ':lion_face:': '🦁',
  ':lips:': '👄',
  ':lipstick:': '💄',
  ':lizard:': '🦎',
  ':lock:': '🔒',
  ':lock_with_ink_pen:': '🔏',
  ':lollipop:': '🍭',
  ':loop:': '➿',
  ':loud_sound:': '🔊',
  ':loudspeaker:': '📢',
  ':love_hotel:': '🏩',
  ':love_letter:': '💌',
  ':low_brightness:': '🔅',
  ':lower_left_ballpoint_pen:': '🖊',
  ':lower_left_crayon:': '🖍',
  ':lower_left_fountain_pen:': '🖋',
  ':lower_left_paintbrush:': '🖌',
  ':lying_face:': '🤥',
  ':m:': 'Ⓜ️',
  ':mag:': '🔍',
  ':mag_right:': '🔎',
  ':mahjong:': '🀄️',
  ':mailbox:': '📫',
  ':mailbox_closed:': '📪',
  ':mailbox_with_mail:': '📬',
  ':mailbox_with_no_mail:': '📭',
  ':male_artist:': '👨‍🎨',
  ':male_astronaut:': '👨‍🚀',
  ':male_cook:': '👨‍🍳',
  ':male_doctor:': '👨‍⚕️',
  ':male_factory_worker:': '👨‍🏭',
  ':male_farmer:': '👨‍🌾',
  ':male_firefighter:': '👨‍🚒',
  ':male_judge:': '👨‍⚖️',
  ':male_mechanic:': '👨‍🔧',
  ':male_office_worker:': '👨‍💼',
  ':male_pilot:': '👨‍✈️',
  ':male_scientist:': '👨‍🔬',
  ':male_sign:': '♂',
  ':male_singer:': '👨‍🎤',
  ':male_student:': '👨‍🎓',
  ':male_teacher:': '👨‍🏫',
  ':male_technologist:': '👨‍💻',
  ':man:': '👨',
  ':man_cartwheeling:': '🤸‍♂️',
  ':man_dancers:': '👯‍♂',
  ':man_dancing:': '🕺',
  ':man_dark_skin_tone:': '👨🏿',
  ':man_facepalming:': '🤦‍♂️',
  ':man_frowning:': '🙍‍♂',
  ':man_frowning_dark_skin_tone:': '🙍🏿‍♂',
  ':man_frowning_light_skin_tone:': '🙍🏻‍♂',
  ':man_frowning_medium_dark_skin_tone:': '🙍🏾‍♂',
  ':man_frowning_medium_light_skin_tone:': '🙍🏼‍♂',
  ':man_frowning_medium_skin_tone:': '🙍🏽‍♂',
  ':man_haircut:': '💇‍♂',
  ':man_haircut_dark_skin_tone:': '💇🏿‍♂',
  ':man_haircut_light_skin_tone:': '💇🏻‍♂',
  ':man_haircut_medium_dark_skin_tone:': '💇🏾‍♂',
  ':man_haircut_medium_light_skin_tone:': '💇🏼‍♂',
  ':man_haircut_medium_skin_tone:': '💇🏽‍♂',
  ':man_in_suit:': '🕴',
  ':man_in_tuxedo:': '🤵',
  ':man_juggling:': '🤹‍♂️',
  ':man_light_skin_tone:': '👨🏻',
  ':man_man_love:': '👨‍❤️‍👨',
  ':man_massage:': '💆‍♂',
  ':man_massage_dark_skin_tone:': '💆🏿‍♂',
  ':man_massage_medium_dark_skin_tone:': '💆🏾‍♂',
  ':man_massage_medium_light_skin_tone:': '💆🏼‍♂',
  ':man_massage_medium_skin_tone:': '💆🏻‍♂',
  ':man_medium_dark_skin_tone:': '👨🏾',
  ':man_medium_light_skin_tone:': '👨🏼',
  ':man_medium_skin_tone:': '👨🏽',
  ':man_no_good:': '🙅‍♂',
  ':man_no_good_dark_skin_tone:': '🙅🏿‍♂',
  ':man_no_good_light_skin_tone:': '🙅🏻‍♂',
  ':man_no_good_medium_dark_skin_tone:': '🙅🏾‍♂',
  ':man_no_good_medium_light_skin_tone:': '🙅🏼‍♂',
  ':man_no_good_medium_skin_tone:': '🙅🏽‍♂',
  ':man_playing_handball:': '🤾‍♂️',
  ':man_playing_water_polo:': '🤽‍♂️',
  ':man_pouting:': '🙎‍♂',
  ':man_pouting_dark_skin_tone:': '🙎🏿‍♂',
  ':man_pouting_light_skin_tone:': '🙎🏻‍♂',
  ':man_pouting_medium_dark_skin_tone:': '🙎🏾‍♂',
  ':man_pouting_medium_light_skin_tone:': '🙎🏼‍♂',
  ':man_pouting_medium_skin_tone:': '🙎🏽‍♂',
  ':man_raising_hand:': '🙋‍♂',
  ':man_raising_hand_dark_skin_tone:': '🙋🏿‍♂',
  ':man_raising_hand_light_skin_tone:': '🙋🏻‍♂',
  ':man_raising_hand_medium_dark_skin_tone:': '🙋🏾‍♂',
  ':man_raising_hand_medium_light_skin_tone:': '🙋🏼‍♂',
  ':man_raising_hand_medium_skin_tone:': '🙋🏽‍♂',
  ':man_shrugging:': '🤷‍♂️',
  ':man_swimmer_dark_skin_tone:': '🏊🏿‍♀',
  ':man_swimmer_medium_dark_skin_tone:': '🏊🏾‍♀',
  ':man_tip_hand_dark_skin_tone:': '💁🏿‍♂',
  ':man_tip_hand_light_skin_tone:': '💁🏻‍♂',
  ':man_tip_hand_medium_dark_skin_tone:': '💁🏾‍♂',
  ':man_tip_hand_medium_light_skin_tone:': '💁🏼‍♂',
  ':man_tip_hand_medium_skin_tone:': '💁🏽‍♂',
  ':man_with_gua_pi_mao:': '👲',
  ':man_with_gua_pi_mao_dark_skin_tone:': '👲🏿',
  ':man_with_gua_pi_mao_light_skin_tone:': '👲🏻',
  ':man_with_gua_pi_mao_medium_dark_skin_tone:': '👲🏾',
  ':man_with_gua_pi_mao_medium_light_skin_tone:': '👲🏼',
  ':man_with_gua_pi_mao_medium_skin_tone:': '👲🏽',
  ':man_wrestling:': '🤼‍♂️',
  ':mans_shoe:': '👞',
  ':mantelpiece_clock:': '🕰',
  ':maple_leaf:': '🍁',
  ':martial_arts_uniform:': '🥋',
  ':mask:': '😷',
  ':massage:': '💆',
  ':massage_dark_skin_tone:': '💆🏿',
  ':massage_medium_dark_skin_tone:': '💆🏾',
  ':massage_medium_light_skin_tone:': '💆🏼',
  ':massage_medium_skin_tone:': '💆🏻',
  ':meat_on_bone:': '🍖',
  ':medal:': '🎖',
  ':mega:': '📣',
  ':melon:': '🍈',
  ':menorah:': '🕎',
  ':mens:': '🚹',
  ':metro:': '🚇',
  ':microphone:': '🎤',
  ':microscope:': '🔬',
  ':middle_finger:': '🖕',
  ':middle_finger_dark_skin_tone:': '🖕🏿',
  ':middle_finger_light_skin_tone:': '🖕🏻',
  ':middle_finger_medium_dark_skin_tone:': '🖕🏾',
  ':middle_finger_medium_light_skin_tone:': '🖕🏼',
  ':middle_finger_medium_skin_tone:': '🖕🏽',
  ':milky_way:': '🌌',
  ':minibus:': '🚐',
  ':minidisc:': '💽',
  ':mobile_phone_off:': '📴',
  ':money_mouth_face:': '🤑',
  ':money_with_wings:': '💸',
  ':moneybag:': '💰',
  ':monkey:': '🐒',
  ':monkey_face:': '🐵',
  ':monorail:': '🚝',
  ':mortar_board:': '🎓',
  ':mosque:': '🕌',
  ':mother_christmas:': '🤶',
  ':motor_boat:': '🛥',
  ':motor_scooter:': '🛵',
  ':motorway:': '🛣',
  ':mount_fuji:': '🗻',
  ':mountain:': '⛰',
  ':mountain_bicyclist:': '🚵',
  ':mountain_bicyclist_dark_skin_tone:': '🚵🏿',
  ':mountain_bicyclist_light_skin_tone:': '🚵🏻',
  ':mountain_bicyclist_medium_dark_skin_tone:': '🚵🏾',
  ':mountain_bicyclist_medium_light_skin_tone:': '🚵🏼',
  ':mountain_bicyclist_medium_skin_tone:': '🚵🏽',
  ':mountain_cableway:': '🚠',
  ':mountain_railway:': '🚞',
  ':mouse2:': '🐁',
  ':mouse:': '🐭',
  ':movie_camera:': '🎥',
  ':moyai:': '🗿',
  ':muscle:': '💪',
  ':muscle_dark_skin_tone:': '💪🏿',
  ':muscle_light_skin_tone:': '💪🏻',
  ':muscle_medium_dark_skin_tone:': '💪🏾',
  ':muscle_medium_light_skin_tone:': '💪🏼',
  ':muscle_medium_skin_tone:': '💪🏽',
  ':mushroom:': '🍄',
  ':musical_keyboard:': '🎹',
  ':musical_note:': '🎵',
  ':musical_score:': '🎼',
  ':mute:': '🔇',
  ':nail_care:': '💅',
  ':nail_care_dark_skin_tone:': '💅🏿',
  ':nail_care_light_skin_tone:': '💅🏻',
  ':nail_care_medium_dark_skin_tone:': '💅🏾',
  ':nail_care_medium_light_skin_tone:': '💅🏼',
  ':nail_care_medium_skin_tone:': '💅🏽',
  ':name_badge:': '📛',
  ':national_park:': '🏞',
  ':nauseated_face:': '🤢',
  ':necktie:': '👔',
  ':negative_squared_cross_mark:': '❎',
  ':nerd:': '🤓',
  ':neutral_face:': '😐',
  ':new:': '🆕',
  ':new_moon:': '🌑',
  ':new_moon_with_face:': '🌚',
  ':newspaper:': '📰',
  ':ng:': '🆖',
  ':night_with_stars:': '🌃',
  ':nine:': '9⃣',
  ':no_bell:': '🔕',
  ':no_bicycles:': '🚳',
  ':no_entry:': '⛔️',
  ':no_entry_sign:': '🚫',
  ':no_good:': '🙅',
  ':no_good_dark_skin_tone:': '🙅🏿',
  ':no_good_light_skin_tone:': '🙅🏻',
  ':no_good_medium_dark_skin_tone:': '🙅🏾',
  ':no_good_medium_light_skin_tone:': '🙅🏼',
  ':no_good_medium_skin_tone:': '🙅🏽',
  ':no_mobile_phones:': '📵',
  ':no_mouth:': '😶',
  ':no_pedestrians:': '🚷',
  ':no_smoking:': '🚭',
  ':non_potable_water:': '🚱',
  ':nose:': '👃',
  ':nose_dark_skin_tone:': '👃🏿',
  ':nose_light_skin_tone:': '👃🏻',
  ':nose_medium_dark_skin_tone:': '👃🏾',
  ':nose_medium_light_skin_tone:': '👃🏼',
  ':nose_medium_skin_tone:': '👃🏽',
  ':notebook:': '📓',
  ':notebook_with_decorative_cover:': '📔',
  ':notes:': '🎶',
  ':number_sign:': '#',
  ':nut_and_bolt:': '🔩',
  ':o2:': '🅾️',
  ':o:': '⭕️',
  ':ocean:': '🌊',
  ':octagonal_sign:': '🛑',
  ':octopus:': '🐙',
  ':oden:': '🍢',
  ':office:': '🏢',
  ':oil_drum:': '🛢',
  ':ok:': '🆗',
  ':ok_hand:': '👌',
  ':ok_hand_dark_skin_tone:': '👌🏿',
  ':ok_hand_light_skin_tone:': '👌🏻',
  ':ok_hand_medium_dark_skin_tone: ': '👌🏾',
  ':ok_hand_medium_light_skin_tone:': '👌🏼',
  ':ok_hand_medium_skin_tone:': '👌🏽',
  ':ok_man:': '🙆‍♂',
  ':ok_man_dark_skin_tone:': '🙆🏿‍♂',
  ':ok_man_light_skin_tone:': '🙆🏻‍♂',
  ':ok_man_medium_dark_skin_tone:': '🙆🏾‍♂',
  ':ok_man_medium_light_skin_tone:': '🙆🏼‍♂',
  ':ok_man_medium_skin_tone:': '🙆🏽‍♂',
  ':ok_woman:': '🙆',
  ':ok_woman_dark_skin_tone:': '🙆🏿',
  ':ok_woman_light_skin_tone:': '🙆🏻',
  ':ok_woman_medium_dark_skin_tone:': '🙆🏾',
  ':ok_woman_medium_light_skin_tone:': '🙆🏼',
  ':ok_woman_medium_skin_tone:': '🙆🏽',
  ':old_key:': '🗝',
  ':older_man:': '👴',
  ':older_man_dark_skin_tone:': '👴🏿',
  ':older_man_light_skin_tone:': '👴🏻',
  ':older_man_medium_dark_skin_tone:': '👴🏾',
  ':older_man_medium_light_skin_tone:': '👴🏼',
  ':older_man_medium_skin_tone:': '👴🏽',
  ':older_woman:': '👵',
  ':older_woman_dark_skin_tone:': '👵🏿',
  ':older_woman_light_skin_tone:': '👵🏻',
  ':older_woman_medium_dark_skin_tone:': '👵🏾',
  ':older_woman_medium_light_skin_tone:': '👵🏼',
  ':older_woman_medium_skin_tone:': '👵🏽',
  ':om_symbol:': '🕉',
  ':on:': '🔛',
  ':oncoming_automobile:': '🚘',
  ':oncoming_bus:': '🚍',
  ':oncoming_police_car:': '🚔',
  ':oncoming_taxi:': '🚖',
  ':one:': '1⃣',
  ':open_file_folder:': '📂',
  ':open_hands:': '👐',
  ':open_hands_dark_skin_tone:': '👐🏿',
  ':open_hands_light_skin_tone:': '👐🏻',
  ':open_hands_medium_dark_skin_tone:': '👐🏾',
  ':open_hands_medium_light_skin_tone:': '👐🏼',
  ':open_hands_medium_skin_tone:': '👐🏽',
  ':open_mouth:': '😮',
  ':ophiuchus:': '⛎',
  ':orange_book:': '📙',
  ':orthodox_cross:': '☦️',
  ':outbox_tray:': '📤',
  ':owl:': '🦉',
  ':ox:': '🐂',
  ':package:': '📦',
  ':page_facing_up:': '📄',
  ':page_with_curl:': '📃',
  ':pager:': '📟',
  ':palm_tree:': '🌴',
  ':pancakes:': '🥞',
  ':panda_face:': '🐼',
  ':paperclip:': '📎',
  ':parking:': '🅿️',
  ':part_alternation_mark:': '〽️',
  ':part_popper:': '🎉',
  ':partly_sunny:': '⛅️',
  ':passenger_ship:': '🛳',
  ':passport_control:': '🛂',
  ':peace:': '☮',
  ':peace_symbol:': '☮️',
  ':peach:': '🍑',
  ':peanuts:': '🥜',
  ':pear:': '🍐',
  ':pencil2:': '✏️',
  ':pencil:': '📝',
  ':penguin:': '🐧',
  ':pensive:': '😔',
  ':performing_arts:': '🎭',
  ':persevere:': '😣',
  ':person_doing_cartwheel:': '🤸',
  ':person_frowning:': '🙍',
  ':person_frowning_dark_skin_tone:': '🙍🏿',
  ':person_frowning_light_skin_tone:': '🙍🏻',
  ':person_frowning_medium_dark_skin_tone:': '🙍🏾',
  ':person_frowning_medium_light_skin_tone:': '🙍🏼',
  ':person_frowning_medium_skin_tone:': '🙍🏽',
  ':person_raising_hand:': '🙋',
  ':person_raising_hand_dark_skin_tone:': '🙋🏿',
  ':person_raising_hand_light_skin_tone:': '🙋🏻',
  ':person_raising_hand_medium_dark_skin_tone:': '🙋🏾',
  ':person_raising_hand_medium_light_skin_tone:': '🙋🏼',
  ':person_raising_hand_medium_skin_tone:': '🙋🏽',
  ':person_tip_hand:': '💁‍♂',
  ':person_with_ball:': '⛹️‍♂️',
  ':person_with_turban:': '👳',
  ':person_with_turban_dark_skin_tone:': '👳🏿',
  ':person_with_turban_light_skin_tone:': '👳🏻',
  ':person_with_turban_medium_dark_skin_tone:': '👳🏾',
  ':person_with_turban_medium_light_skin_tone:': '👳🏼',
  ':person_with_turban_medium_skin_tone:': '👳🏽',
  ':pick:': '⛏',
  ':pig2:': '🐖',
  ':pig:': '🐷',
  ':pig_nose:': '🐽',
  ':pill:': '💊',
  ':pineapple:': '🍍',
  ':pisces:': '♓️',
  ':pizza:': '🍕',
  ':place_of_worship:': '🛐',
  ':play_pause:': '⏯',
  ':point_down:': '👇',
  ':point_down_dark_skin_tone:': '👇🏿',
  ':point_down_light_skin_tone:': '👇🏻',
  ':point_down_medium_dark_skin_tone:': '👇🏾',
  ':point_down_medium_light_skin_tone:': '👇🏼',
  ':point_down_medium_skin_tone:': '👇🏽',
  ':point_left:': '👈',
  ':point_left_dark_skin_tone:': '👈🏿',
  ':point_left_light_skin_tone:': '👈🏻',
  ':point_left_medium_dark_skin_tone:': '👈🏾',
  ':point_left_medium_light_skin_tone:': '👈🏼',
  ':point_left_medium_skin_tone:': '👈🏽',
  ':point_right:': '👉',
  ':point_right_dark_skin_tone:': '👉🏿',
  ':point_right_light_skin_tone:': '👉🏻',
  ':point_right_medium_dark_skin_tone:': '👉🏾',
  ':point_right_medium_light_skin_tone:': '👉🏼',
  ':point_right_medium_skin_tone:': '👉🏽',
  ':point_up2_dark_skin_tone:': '👆🏿',
  ':point_up2_light_skin_tone:': '👆🏻',
  ':point_up2_medium_dark_skin_tone:': '👆🏾',
  ':point_up2_medium_light_skin_tone:': '👆🏼',
  ':point_up2_medium_skin_tone:': '👆🏽',
  ':point_up:': '☝️',
  ':point_up_2:': '👆',
  ':point_up_dark_skin_tone:': '☝🏿',
  ':point_up_light_skin_tone:': '☝🏻',
  ':point_up_medium_dark_skin_tone:': '☝🏾',
  ':point_up_medium_light_skin_tone:': '☝🏼',
  ':point_up_medium_skin_tone:': '☝🏽',
  ':police_car:': '🚓',
  ':poodle:': '🐩',
  ':poop:': '💩',
  ':popcorn:': '🍿',
  ':post_office:': '🏣',
  ':postal_horn:': '📯',
  ':postbox:': '📮',
  ':potable_water:': '🚰',
  ':potato:': '🥔',
  ':pouch:': '👝',
  ':poultry_leg:': '🍗',
  ':pound:': '💷',
  ':pouting:': '🙎',
  ':pouting_cat:': '😾',
  ':pouting_dark_skin_tone:': '🙎🏿',
  ':pouting_light_skin_tone:': '🙎🏻',
  ':pouting_medium_dark_skin_tone:': '🙎🏾',
  ':pouting_medium_light_skin_tone:': '🙎🏼',
  ':pouting_medium_skin_tone:': '🙎🏽',
  ':pray:': '🙏',
  ':pray_dark_skin_tone:': '🙏🏿',
  ':pray_light_skin_skin_tone:': '🙏🏻',
  ':pray_medium_dark_skin_tone:': '🙏🏾',
  ':pray_medium_light_skin_skin_tone:': '🙏🏼',
  ':pray_medium_skin_skin_tone:': '🙏🏽',
  ':prayer_beads:': '📿',
  ':pregnant_woman:': '🤰',
  ':prince:': '🤴',
  ':princess:': '👸',
  ':princess_dark_skin_tone:': '👸🏿',
  ':princess_medium_dark_skin_tone:': '👸🏾',
  ':princess_medium_light_skin_tone:': '👸🏼',
  ':princess_medium_skin_tone:': '👸🏻',
  ':printer:': '🖨',
  ':punch:': '👊',
  ':punch_dark_skin_tone:': '👊🏿',
  ':punch_light_skin_tone:': '👊🏻',
  ':punch_medium_dark_skin_tone:': '👊🏾',
  ':punch_medium_light_skin_tone:': '👊🏼',
  ':punch_medium_skin_tone:': '👊🏽',
  ':purple_heart:': '💜',
  ':purse:': '👛',
  ':pushpin:': '📌',
  ':put_litter_in_its_place:': '🚮',
  ':question:': '❓',
  ':rabbit2:': '🐇',
  ':rabbit:': '🐰',
  ':racehorse:': '🐎',
  ':racing_car:': '🏎',
  ':racing_motorcycle:': '🏍',
  ':radio:': '📻',
  ':radio_button:': '🔘',
  ':radioactive:': '☢',
  ':radioactive_sign:': '☢️',
  ':rage:': '😡',
  ':railway_car:': '🚃',
  ':railway_track:': '🛤',
  ':rain_cloud:': '🌧',
  ':rainbow:': '🌈',
  ':rainbow_flag:': '🏳️‍🌈',
  ':raised_back_of_hand:': '🤚',
  ':raised_hand:': '✋',
  ':raised_hand_dark_skin_tone:': '✋🏿',
  ':raised_hand_light_skin_tone:': '✋🏻',
  ':raised_hand_medium_dark_skin_tone:': '✋🏾',
  ':raised_hand_medium_light_skin_tone:': '✋🏼',
  ':raised_hand_medium_skin_tone:': '✋🏽',
  ':raised_hand_with_fingers_splayed:': '🖐',
  ':raised_hand_with_fingers_splayed_dark_skin_tone:': '🖐🏿',
  ':raised_hand_with_fingers_splayed_light_skin_tone:': '🖐🏻',
  ':raised_hand_with_fingers_splayed_medium_dark_skin_tone:': '🖐🏾',
  ':raised_hand_with_fingers_splayed_medium_light_skin_tone:': '🖐🏼',
  ':raised_hand_with_fingers_splayed_medium_skin_tone:': '🖐🏽',
  ':raised_hands:': '🙌',
  ':raised_hands_light_skin_tone:': '🙌🏻',
  ':raised_hands_medium_dark_skin_tone:': '🙌🏾',
  ':raised_hands_medium_light_skin_tone:': '🙌🏼',
  ':raised_hands_medium_skin_tone:': '🙌🏽',
  ':ram:': '🐏',
  ':ramen:': '🍜',
  ':rat:': '🐀',
  ':recycle:': '♻️',
  ':red_car:': '🚗',
  ':red_circle:': '🔴',
  ':registered:': '®',
  ':relieved:': '😌',
  ':reminder_ribbon:': '🎗',
  ':repeat:': '🔁',
  ':repeat_one:': '🔂',
  ':restroom:': '🚻',
  ':revolving_hearts:': '💞',
  ':rewind:': '⏪',
  ':rhinoceros:': '🦏',
  ':ribbon:': '🎀',
  ':rice:': '🍚',
  ':rice_ball:': '🍙',
  ':rice_cracker:': '🍘',
  ':rice_scene:': '🎑',
  ':right_facing_fist:': '🤜',
  ':ring:': '💍',
  ':robot_face:': '🤖',
  ':rocket:': '🚀',
  ':rolled_up_newspaper:': '🗞',
  ':roller_coaster:': '🎢',
  ':rolling_eyes:': '🙄',
  ':rolling_on_the_floor_laughing:': '🤣',
  ':rooster:': '🐓',
  ':rose:': '🌹',
  ':rosette:': '🏵',
  ':rotating_light:': '🚨',
  ':round_pushpin:': '📍',
  ':rowboat:': '🚣',
  ':rowboat_dark_skin_tone:': '🚣🏿',
  ':rowboat_light_skin_tone:': '🚣🏻',
  ':rowboat_medium_dark_skin_tone:': '🚣🏾',
  ':rowboat_medium_light_skin_tone:': '🚣🏼',
  ':rowboat_medium_skin_tone:': '🚣🏽',
  ':rugby_football:': '🏉',
  ':runner:': '🏃',
  ':runner_dark_skin_tone:': '🏃🏿',
  ':runner_light_skin_tone:': '🏃🏻',
  ':runner_medium_dark_skin_tone:': '🏃🏾',
  ':runner_medium_light_skin_tone:': '🏃🏼',
  ':runner_medium_skin_tone:': '🏃🏽',
  ':running:': '🏃‍♂️',
  ':running_shirt_with_sash:': '🎽',
  ':sa:': '🈂️',
  ':sagittarius:': '♐️',
  ':sailboat:': '⛵️',
  ':sake:': '🍶',
  ':sandal:': '👡',
  ':santa:': '🎅',
  ':santa_dark_skin_tone:': '🎅🏿',
  ':santa_light_skin_tone:': '🎅🏻',
  ':santa_medium_dark_skin_tone:': '🎅🏾',
  ':santa_medium_light_skin_tone:': '🎅🏼',
  ':santa_medium_skin_tone:': '🎅🏽',
  ':satellite:': '🛰',
  ':saxophone:': '🎷',
  ':scales:': '⚖️',
  ':school:': '🏫',
  ':school_satchel:': '🎒',
  ':scissors:': '✂️',
  ':scooter:': '🛴',
  ':scorpion:': '🦂',
  ':scorpius:': '♏️',
  ':scream:': '😱',
  ':scream_cat:': '🙀',
  ':scroll:': '📜',
  ':seat:': '💺',
  ':second_place_medal:': '🥈',
  ':secret:': '㊙️',
  ':see_no_evil:': '🙈',
  ':seedling:': '🌱',
  ':selfie:': '🤳',
  ':seven:': '7⃣',
  ':shallow_pan_of_food:': '🥘',
  ':shamrock:': '☘️',
  ':shark:': '🦈',
  ':shaved_ice:': '🍧',
  ':sheep:': '🐑',
  ':shell:': '🐚',
  ':shield:': '🛡',
  ':shinto_shrine:': '⛩',
  ':ship:': '🚢',
  ':shirt:': '👕',
  ':shopping_bags:': '🛍',
  ':shopping_trolley:': '🛒',
  ':shower:': '🚿',
  ':shrimp:': '🦐',
  ':shrug:': '🤷',
  ':sign_of_the_horns:': '🤘',
  ':sign_of_the_horns_dark_skin_tone:': '🤘🏿',
  ':sign_of_the_horns_light_skin_tone:': '🤘🏻',
  ':sign_of_the_horns_medium_dark_skin_tone:': '🤘🏾',
  ':sign_of_the_horns_medium_light_skin_tone:': '🤘🏼',
  ':sign_of_the_horns_medium_skin_tone:': '🤘🏽',
  ':signal_strength:': '📶',
  ':single_father_one_boy:': '👨‍👦',
  ':single_father_one_girl:': '👨‍👧',
  ':single_father_two_boys:': '👨‍👦‍👦',
  ':single_father_two_girls:': '👨‍👧‍👧',
  ':single_father_with_children:': '👨‍👧‍👦',
  ':single_mother_one_boy:': '👩‍👦',
  ':single_mother_one_girl:': '👩‍👧',
  ':single_mother_two_boys:': '👩‍👦‍👦',
  ':single_mother_two_children:': '👩‍👧‍👦',
  ':single_mother_two_girls:': '👩‍👧‍👧',
  ':six:': '6⃣',
  ':six_pointed_star:': '🔯',
  ':ski:': '🎿',
  ':skier:': '⛷',
  ':skin_tone_2:': '🏻',
  ':skin_tone_3:': '🏼',
  ':skin_tone_4:': '🏽',
  ':skin_tone_5:': '🏾',
  ':skin_tone_6:': '🏿',
  ':skull:': '💀',
  ':skull_and_crossbones:': '☠️',
  ':skull_crossbones:': '☠',
  ':sleeping:': '😴',
  ':sleeping_accommodation:': '🛌',
  ':sleepy:': '😪',
  ':slight_smile:': '🙂',
  ':slightly_frowning_face:': '🙁',
  ':slot_machine:': '🎰',
  ':small_airplane:': '🛩',
  ':small_blue_diamond:': '🔹',
  ':small_orange_diamond:': '🔸',
  ':small_red_triangle:': '🔺',
  ':small_red_triangle_down:': '🔻',
  ':smile:': '😄',
  ':smile_cat:': '😸',
  ':smiley:': '😃',
  ':smiley_cat:': '😺',
  ':smiling:': '☺️',
  ':smiling_imp:': '😈',
  ':smirk:': '😏',
  ':smirk_cat:': '😼',
  ':smoking:': '🚬',
  ':snail:': '🐌',
  ':snake:': '🐍',
  ':sneezing_face:': '🤧',
  ':snow_capped_mountain:': '🏔',
  ':snow_cloud:': '🌨',
  ':snowboarder:': '🏂',
  ':snowflake:': '❄️',
  ':snowman:': '☃️',
  ':snowman_without_snow:': '⛄️',
  ':sob:': '😭',
  ':soccer:': '⚽️',
  ':soon:': '🔜',
  ':sos:': '🆘',
  ':sound:': '🔉',
  ':space_invader:': '👾',
  ':spades:': '♠',
  ':spaghetti:': '🍝',
  ':sparkle:': '❇️',
  ':sparkler:': '🎇',
  ':sparkles:': '✨',
  ':sparkling_heart:': '💖',
  ':speak_no_evil:': '🙊',
  ':speaker:': '🔈',
  ':speaking_head_in_silhouette:': '🗣',
  ':speech_balloon:': '💬',
  ':speech_left:': '🗨',
  ':speedboat:': '🚤',
  ':spider:': '🕷',
  ':spider_web:': '🕸',
  ':spiral_calendar_pad:': '🗓',
  ':spiral_note_pad:': '🗒',
  ':spock_hand:': '🖖',
  ':spock_hand_dark_skin_tone:': '🖖🏿',
  ':spock_hand_light_skin_tone:': '🖖🏻',
  ':spock_hand_medium_dark_skin_tone:': '🖖🏾',
  ':spock_hand_medium_light_skin_tone:': '🖖🏼',
  ':spock_hand_medium_skin_tone:': '🖖🏽',
  ':spoon:': '🥄',
  ':squid:': '🦑',
  ':stadium:': '🏟',
  ':staff_of_aesculapius:': '⚕',
  ':star2:': '🌟',
  ':star:': '⭐️',
  ':star_and_crescent:': '☪️',
  ':star_of_david:': '✡️',
  ':stars:': '🌠',
  ':station:': '🚉',
  ':statue_of_liberty:': '🗽',
  ':steam_locomotive:': '🚂',
  ':stew:': '🍲',
  ':stopwatch:': '⏱',
  ':straight_ruler:': '📏',
  ':strawberry:': '🍓',
  ':stuck_out_tongue:': '😛',
  ':stuck_out_tongue_closed_eyes:': '😝',
  ':stuck_out_tongue_winking_eye:': '😜',
  ':studio_microphone:': '🎙',
  ':stuffed_flatbread:': '🥙',
  ':sun_behind_cloud:': '🌥',
  ':sun_behind_rain_cloud:': '🌦',
  ':sun_small_cloud:': '🌤',
  ':sun_with_face:': '🌞',
  ':sunflower:': '🌻',
  ':sunglasses:': '😎',
  ':sunny:': '☀️',
  ':sunrise:': '🌅',
  ':sunrise_over_mountains:': '🌄',
  ':surfer:': '🏄‍♂️',
  ':surfer_dark_skin_tone:': '🏄🏿',
  ':surfer_light_skin_tone:': '🏄🏻',
  ':surfer_medium_dark_skin_tone:': '🏄🏾',
  ':surfer_medium_light_skin_tone:': '🏄🏼',
  ':surfer_medium_skin_tone:': '🏄🏽',
  ':sushi:': '🍣',
  ':suspension_railway:': '🚟',
  ':sweat:': '😓',
  ':sweat_drops:': '💦',
  ':sweat_smile:': '😅',
  ':sweet_potato:': '🍠',
  ':swimmer:': '🏊‍♂️',
  ':swimmer_dark_skin_tone:': '🏊🏿',
  ':swimmer_light_skin_tone:': '🏊🏻',
  ':swimmer_medium_dark_skin_tone:': '🏊🏾',
  ':swimmer_medium_light_skin_tone:': '🏊🏼',
  ':swimmer_medium_skin_tone:': '🏊🏽',
  ':symbols:': '🔣',
  ':synagogue:': '🕍',
  ':syringe:': '💉',
  ':table_tennis_paddle_and_ball:': '🏓',
  ':taco:': '🌮',
  ':tanabata_tree:': '🎋',
  ':tangerine:': '🍊',
  ':taurus:': '♉️',
  ':taxi:': '🚕',
  ':tea:': '🍵',
  ':telephone:': '☎️',
  ':telephone_receiver:': '📞',
  ':telescope:': '🔭',
  ':tennis:': '🎾',
  ':tent:': '⛺️',
  ':thermometer:': '🌡',
  ':thinking:': '🤔',
  ':third_place_medal:': '🥉',
  ':thought_balloon:': '💭',
  ':three:': '3⃣',
  ':three_button_mouse:': '🖱',
  ':thumbsdown:': '👎',
  ':thumbsdown_dark_skin_tone:': '👎🏿',
  ':thumbsdown_light_skin_tone:': '👎🏻',
  ':thumbsdown_medium_dark_skin_tone:': '👎🏾',
  ':thumbsdown_medium_light_skin_tone:': '👎🏼',
  ':thumbsdown_medium_skin_tone:': '👎🏽',
  ':thumbsup:': '👍',
  ':thumbsup_dark_skin_tone:': '👍🏿',
  ':thumbsup_light_skin_tone:': '👍🏻',
  ':thumbsup_medium_dark_skin_tone:': '👍🏾',
  ':thumbsup_medium_light_skin_tone:': '👍🏼',
  ':thumbsup_medium_skin_tone:': '👍🏽',
  ':thunder_cloud_and_rain:': '⛈',
  ':ticket:': '🎫',
  ':tiger2:': '🐅',
  ':tiger:': '🐯',
  ':timer_clock:': '⏲',
  ':tired_face:': '😫',
  ':tm:': '™',
  ':toilet:': '🚽',
  ':tokyo_tower:': '🗼',
  ':tomato:': '🍅',
  ':tongue:': '👅',
  ':top:': '🔝',
  ':tophat:': '🎩',
  ':tornado_cloud:': '🌪',
  ':track_next:': '⏭',
  ':track_previous:': '⏮',
  ':trackball:': '🖲',
  ':tractor:': '🚜',
  ':traffic_light:': '🚥',
  ':train2:': '🚆',
  ':train:': '🚋',
  ':tram:': '🚊',
  ':triangular_flag_on_post:': '🚩',
  ':triangular_ruler:': '📐',
  ':trident:': '🔱',
  ':triumph:': '😤',
  ':trolleybus:': '🚎',
  ':trophy:': '🏆',
  ':tropical_drink:': '🍹',
  ':tropical_fish:': '🐠',
  ':truck:': '🚚',
  ':trumpet:': '🎺',
  ':tulip:': '🌷',
  ':tumbler_glass:': '🥃',
  ':turkey:': '🦃',
  ':turtle:': '🐢',
  ':tv:': '📺',
  ':twisted_rightwards_arrows:': '🔀',
  ':two:': '2⃣',
  ':two_hearts:': '💕',
  ':two_men_holding_hands:': '👬',
  ':two_women_holding_hands:': '👭',
  ':u5272:': '🈹',
  ':u5408:': '🈴',
  ':u55b6:': '🈺',
  ':u6307:': '🈯️',
  ':u6708:': '🈷️',
  ':u6709:': '🈶',
  ':u6e80:': '🈵',
  ':u7121:': '🈚️',
  ':u7533:': '🈸',
  ':u7981:': '🈲',
  ':u7a7a:': '🈳',
  ':umbrella:': '☔️',
  ':umbrella_with_rain_drops:': '☂️',
  ':unamused:': '😒',
  ':underage:': '🔞',
  ':unicorn_face:': '🦄',
  ':unlock:': '🔓',
  ':up:': '🆙',
  ':upside_down:': '🙃',
  ':v:': '✌️',
  ':v_dark_skin_tone:': '✌🏿',
  ':v_light_skin_tone:': '✌🏻',
  ':v_medium_dark_skin_tone:': '✌🏾',
  ':v_medium_light_skin_tone:': '✌🏼',
  ':v_medium_skin_tone:': '✌🏽',
  ':vertical_traffic_light:': '🚦',
  ':vhs:': '📼',
  ':vibration_mode:': '📳',
  ':video_camera:': '📹',
  ':video_game:': '🎮',
  ':violin:': '🎻',
  ':virgo:': '♍️',
  ':volcano:': '🌋',
  ':volleyball:': '🏐',
  ':vs:': '🆚',
  ':walking:': '🚶',
  ':walking_dark_skin_tone:': '🚶🏿',
  ':walking_light_skin_tone:': '🚶🏻',
  ':walking_medium_dark_skin_tone:': '🚶🏾',
  ':walking_medium_light_skin_tone:': '🚶🏼',
  ':walking_medium_skin_tone:': '🚶🏽',
  ':waning_crescent_moon:': '🌘',
  ':waning_gibbous_moon:': '🌖',
  ':warning:': '⚠️',
  ':wastebasket:': '🗑',
  ':watch:': '⌚️',
  ':water_buffalo:': '🐃',
  ':water_polo:': '🤽',
  ':watermelon:': '🍉',
  ':wave:': '👋',
  ':wave_dark_skin_tone:': '👋🏿',
  ':wave_light_skin_tone:': '👋🏻',
  ':wave_medium_dark_skin_tone:': '👋🏾',
  ':wave_medium_light_skin_tone:': '👋🏼',
  ':wave_medium_skin_tone:': '👋🏽',
  ':waving_white_flag:': '🏳️',
  ':wavy_dash:': '〰️',
  ':waxing_crescent_moon:': '🌒',
  ':waxing_gibbous_moon:': '🌔',
  ':wc:': '🚾',
  ':weary:': '😩',
  ':wedding:': '💒',
  ':weight_lifter:': '🏋️‍♂️',
  ':weightlifter:': '🏋',
  ':weightlifter_dt:': '🏋🏿',
  ':weightlifter_lt:': '🏋🏻',
  ':weightlifter_mdt:': '🏋🏾',
  ':weightlifter_mlt:': '🏋🏼',
  ':weightlifter_mt:': '🏋🏽',
  ':weightlifter_woman:': '🏋‍♀',
  ':weightlifter_woman_dt:': '🏋🏿‍♀',
  ':weightlifter_woman_lt:': '🏋🏻‍♀',
  ':weightlifter_woman_mdt:': '🏋🏾‍♀',
  ':weightlifter_woman_mlt:': '🏋🏼‍♀',
  ':weightlifter_woman_mt:': '🏋🏽‍♀',
  ':whale2:': '🐋',
  ':whale:': '🐳',
  ':wheel_of_dharma:': '☸️',
  ':wheelchair:': '♿️',
  ':white_check_mark:': '✅',
  ':white_circle:': '⚪️',
  ':white_flower:': '💮',
  ':white_frowning_face:': '☹️',
  ':white_large_square:': '⬜️',
  ':white_medium_small_square:': '◽️',
  ':white_medium_square:': '◻️',
  ':white_small_square:': '▫️',
  ':white_square_button:': '🔳',
  ':wilted_flower:': '🥀',
  ':wind_blowing_face:': '🌬',
  ':wind_chime:': '🎐',
  ':wine_glass:': '🍷',
  ':wink:': '😉',
  ':wolf:': '🐺',
  ':woman:': '👩',
  ':woman_bicyclist:': '🚴‍♀',
  ':woman_bicyclist_dark_skin_tone:': '🚴🏿‍♀',
  ':woman_bicyclist_light_skin_tone:': '🚴🏻‍♀',
  ':woman_bicyclist_medium_dark_skin_tone:': '🚴🏾‍♀',
  ':woman_bicyclist_medium_light_skin_tone:': '🚴🏼‍♀',
  ':woman_bicyclist_medium_skin_tone:': '🚴🏽‍♀',
  ':woman_blond_hair:': '👱‍♀',
  ':woman_blond_hair_dark_skin_tone:': '👱🏿‍♀',
  ':woman_blond_hair_light_skin_tone:': '👱🏻‍♀',
  ':woman_blond_hair_medium_dark_skin_tone:': '👱🏾‍♀',
  ':woman_blond_hair_medium_light_skin_tone:': '👱🏼‍♀',
  ':woman_blond_hair_medium_skin_tone:': '👱🏽‍♀',
  ':woman_bouncing_ball:': '⛹️‍♀️',
  ':woman_cartwheeling:': '🤸‍♀️',
  ':woman_construction_worker:': '👷‍♀',
  ':woman_construction_worker_dark_skin_tone:': '👷🏿‍♀',
  ':woman_construction_worker_light_skin_tone:': '👷🏻‍♀',
  ':woman_construction_worker_medium_dark_skin_tone:': '👷🏾‍♀',
  ':woman_construction_worker_medium_light_skin_tone:': '👷🏼‍♀',
  ':woman_construction_worker_medium_skin_tone:': '👷🏽‍♀',
  ':woman_cop:': '👮‍♀',
  ':woman_cop_dark_skin_tone:': '👮🏿‍♀',
  ':woman_cop_light_skin_tone:': '👮🏻‍♀',
  ':woman_cop_medium_dark_skin_tone:': '👮🏾‍♀',
  ':woman_cop_medium_light_skin_tone:': '👮🏼‍♀',
  ':woman_cop_medium_skin_tone:': '👮🏽‍♀',
  ':woman_dark_skin_tone:': '👩🏿',
  ':woman_detective:': '🕵‍♀',
  ':woman_detective_dark_skin_tone:': '🕵🏿‍♀',
  ':woman_detective_light_skin_tone:': '🕵🏻‍♀',
  ':woman_detective_medium_dark_skin_tone:': '🕵🏾‍♀',
  ':woman_detective_medium_light_skin_tone:': '🕵🏼‍♀',
  ':woman_detective_medium_skin_tone:': '🕵🏽‍♀',
  ':woman_facepalming:': '🤦‍♀️',
  ':woman_golfing:': '🏌️‍♀️',
  ':woman_guardsman:': '💂‍♀',
  ':woman_guardsman_dark_skin_tone:': '💂🏿‍♀',
  ':woman_guardsman_light_skin_tone:': '💂🏻‍♀',
  ':woman_guardsman_medium_dark_skin_tone:': '💂🏾‍♀',
  ':woman_guardsman_medium_light_skin_tone:': '💂🏼‍♀',
  ':woman_guardsman_medium_skin_tone:': '💂🏽‍♀',
  ':woman_juggling:': '🤹‍♀️',
  ':woman_lifting_weights:': '🏋️‍♀️',
  ':woman_light_skin_tone:': '👩🏻',
  ':woman_medium_dark_skin_tone:': '👩🏾',
  ':woman_medium_light_skin_tone:': '👩🏼',
  ':woman_medium_skin_tone:': '👩🏽',
  ':woman_mountain_bicyclist:': '🚵‍♀',
  ':woman_mountain_bicyclist_dark_skin_tone:': '🚵🏿‍♀',
  ':woman_mountain_bicyclist_light_skin_tone:': '🚵🏻‍♀',
  ':woman_mountain_bicyclist_medium_dark_skin_tone:': '🚵🏾‍♀',
  ':woman_mountain_bicyclist_medium_light_skin_tone:': '🚵🏼‍♀',
  ':woman_mountain_bicyclist_medium_skin_tone:': '🚵🏽‍♀',
  ':woman_playing_handball:': '🤾‍♀️',
  ':woman_playing_water_polo:': '🤽‍♀️',
  ':woman_rowboat:': '🚣‍♀',
  ':woman_rowboat_dark_skin_tone:': '🚣🏿‍♀',
  ':woman_rowboat_light_skin_tone:': '🚣🏻‍♀',
  ':woman_rowboat_medium_dark_skin_tone:': '🚣🏾‍♀',
  ':woman_rowboat_medium_light_skin_tone:': '🚣🏼‍♀',
  ':woman_rowboat_medium_skin_tone:': '🚣🏽‍♀',
  ':woman_runner': '🏃‍♀',
  ':woman_runner_dark_skin_tone:': '🏃🏿‍♀',
  ':woman_runner_light_skin_tone:': '🏃🏻‍♀',
  ':woman_runner_medium_dark_skin_tone:': '🏃🏾‍♀',
  ':woman_runner_medium_light_skin_tone:': '🏃🏼‍♀',
  ':woman_runner_medium_skin_tone:': '🏃🏽‍♀',
  ':woman_running:': '🏃‍♀️',
  ':woman_shrugging:': '🤷‍♀️',
  ':woman_surfer:': '🏄‍♀',
  ':woman_surfer_dark_skin_tone:': '🏄🏿‍♀',
  ':woman_surfer_light_skin_tone:': '🏄🏻‍♀',
  ':woman_surfer_medium_dark_skin_tone:': '🏄🏾‍♀',
  ':woman_surfer_medium_light_skin_tone:': '🏄🏼‍♀',
  ':woman_surfer_medium_skin_tone:': '🏄🏽‍♀',
  ':woman_surfing:': '🏄‍♀️',
  ':woman_swimmer:': '🏊‍♀',
  ':woman_swimmer_light_skin_tone:': '🏊🏻‍♀',
  ':woman_swimmer_medium_light_skin_tone:': '🏊🏼‍♀',
  ':woman_swimmer_medium_skin_tone:': '🏊🏽‍♀',
  ':woman_swimming:': '🏊‍♀️',
  ':woman_walking:': '🚶‍♀',
  ':woman_walking_dark_skin_tone:': '🚶🏿‍♀',
  ':woman_walking_light_skin_tone:': '🚶🏻‍♀',
  ':woman_walking_medium_dark_skin_tone:': '🚶🏾‍♀',
  ':woman_walking_medium_light_skin_tone:': '🚶🏼‍♀',
  ':woman_walking_medium_skin_tone:': '🚶🏽‍♀',
  ':woman_with_turban:': '👳‍♀',
  ':woman_with_turban_dark_skin_tone:': '👳🏿‍♀',
  ':woman_with_turban_light_skin_tone:': '👳🏻‍♀',
  ':woman_with_turban_medium_dark_skin_tone:': '👳🏾‍♀',
  ':woman_with_turban_medium_light_skin_tone:': '👳🏼‍♀',
  ':woman_with_turban_medium_skin_tone:': '👳🏽‍♀',
  ':woman_wrestling:': '🤼‍♀️',
  ':womans_clothes:': '👚',
  ':womans_hat:': '👒',
  ':womens:': '🚺',
  ':world_map:': '🗺',
  ':worried:': '😟',
  ':wrench:': '🔧',
  ':wrestlers:': '🤼',
  ':writing_hand:': '✍️',
  ':writing_hand_dark_skin_tone:': '✍🏿',
  ':writing_hand_light_skin_tone:': '✍🏻',
  ':writing_hand_medium_dark_skin_tone:': '✍🏾',
  ':writing_hand_medium_light_skin_tone:': '✍🏼',
  ':writing_hand_medium_skin_tone:': '✍🏽',
  ':x:': '❌',
  ':yellow_heart:': '💛',
  ':yen:': '💴',
  ':yin_yang:': '☯️',
  ':yum:': '😋',
  ':zap:': '⚡️',
  ':zero:': '0⃣',
  ':zipper_mouth:': '🤐',
  ':zzz:': '💤',
};
