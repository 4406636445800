import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each errors as |error|}}\n  <div>{{error.message}}</div>\n{{/each}}", {"contents":"{{#each errors as |error|}}\n  <div>{{error.message}}</div>\n{{/each}}","moduleName":"amber-ui/components/form-control-feedback.hbs","parseOptions":{"srcName":"amber-ui/components/form-control-feedback.hbs"}});
import Component from '@ember/component';

const FormControlFeedbackComponent = Component.extend({
  errors: [],
  classNames: ['invalid-feedback'],
});

FormControlFeedbackComponent.reopenClass({
  positionalParams: ['errors'],
});

export default FormControlFeedbackComponent;
