import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{label}}", {"contents":"{{label}}","moduleName":"amber-ui/components/boolean-tag.hbs","parseOptions":{"srcName":"amber-ui/components/boolean-tag.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

const BooleanTagComponent = Component.extend({
  tagName: 'span',
  classNames: ['badge'],
  classNameBindings: ['value:bg-success:bg-danger'],
  label: computed('value', function () {
    switch (this.value) {
      case null:
        return 'Niet ingevuld';
      case true:
        return 'Ja';
      case false:
      default:
        return 'Nee';
    }
  }),
});

BooleanTagComponent.reopenClass({
  positionalParams: ['value'],
});

export default BooleanTagComponent;
