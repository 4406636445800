import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='card-header mt-2'>\n  <div class='d-flex justify-content-between'>\n    <h5>Inschrijven</h5>\n    <span class='text-muted'>{{form-opened-label form}}</span>\n  </div>\n</div>\n<div class='card-body'>\n  <form {{action 'submitResponse' on='submit'}}>\n    {{form/response/response-form form response}}\n\n    <ModelForm::FormActions\n      @errors={{form.errors}}\n      @errorMessage={{this.errorMessage}}\n      @submitButtonLabel='Inschrijven'\n      @inputLayout='vertical'\n    >\n      {{#if form.questions}}\n        <LinkTo @route='activities' class='btn btn-default'>\n          Annuleren\n        </LinkTo>\n        Vragen met een * zijn verplicht\n      {{/if}}\n    </ModelForm::FormActions>\n  </form>\n</div>", {"contents":"<div class='card-header mt-2'>\n  <div class='d-flex justify-content-between'>\n    <h5>Inschrijven</h5>\n    <span class='text-muted'>{{form-opened-label form}}</span>\n  </div>\n</div>\n<div class='card-body'>\n  <form {{action 'submitResponse' on='submit'}}>\n    {{form/response/response-form form response}}\n\n    <ModelForm::FormActions\n      @errors={{form.errors}}\n      @errorMessage={{this.errorMessage}}\n      @submitButtonLabel='Inschrijven'\n      @inputLayout='vertical'\n    >\n      {{#if form.questions}}\n        <LinkTo @route='activities' class='btn btn-default'>\n          Annuleren\n        </LinkTo>\n        Vragen met een * zijn verplicht\n      {{/if}}\n    </ModelForm::FormActions>\n  </form>\n</div>","moduleName":"amber-ui/components/form/response/response-card.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/response-card.hbs"}});
import Component from '@ember/component';
import { inject as service } from '@ember/service';

const FormResponseCardComponent = Component.extend({
  flashNotice: service('flash-notice'),
  classNames: ['card'],
  form: null,
  response: null,
  errorMessage: null,
  actions: {
    submitResponse() {
      this.response
        .saveWithAnswers()
        .then(() => {
          // The response is the first thing that is saved (in order to save answers), so currently the response is
          // always 'incomplete'. Furthermore, the form has a field 'amountOfResponses' which should be updated.
          // We now reload the response and the corresponding form.
          this.response.reload();
          this.form.reload();
          this.flashNotice.sendSuccess('Inschrijving opgeslagen');
        })
        .catch((error) => {
          this.set('errorMessage', error.message);
          if (
            error.payload?.errors &&
            error.payload.errors.isAny(
              'source.pointer',
              '/data/attributes/user'
            )
          ) {
            this.set(
              'errorMessage',
              'Er is al een response gevonden, probeer eerst te refreshen, zie je dit formulier dan nog? Neem dan contact op met de ict-commissie.'
            );
          }
        });
    },
  },
});

FormResponseCardComponent.reopenClass({
  positionalParams: ['form', 'response'],
});

export default FormResponseCardComponent;
