import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='row justify-content-md-between flex-wrap page-actions'>\n  {{#if (or pageActions (has-block))}}\n    {{#page-actions-buttons pageActions}}\n      {{yield}}\n    {{/page-actions-buttons}}\n  {{/if}}\n</div>", {"contents":"<div class='row justify-content-md-between flex-wrap page-actions'>\n  {{#if (or pageActions (has-block))}}\n    {{#page-actions-buttons pageActions}}\n      {{yield}}\n    {{/page-actions-buttons}}\n  {{/if}}\n</div>","moduleName":"amber-ui/components/page-actions.hbs","parseOptions":{"srcName":"amber-ui/components/page-actions.hbs"}});
import Component from '@ember/component';

const PageActionsComponent = Component.extend({});

PageActionsComponent.reopenClass({
  positionalParams: ['pageActions'],
});

export default PageActionsComponent;
