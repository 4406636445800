import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if showCheckbox}}\n  <th></th>\n{{/if}}\n\n<th class=\"align-top\"><strong>{{form.amountOfResponses}}</strong></th>\n\n{{#each form.sortedQuestions as |question|}}\n  <th class=\"align-top\">\n    {{#if question.isOpenQuestion}}\n      <strong>{{question.sumOfAnswers}}</strong>\n    {{else}}\n      {{#each question.sortedOptions as |opt|}}\n        <strong>{{opt.sumOfAnswers}}</strong>\n        {{opt.option}}<br />\n      {{/each}}\n    {{/if}}\n  </th>\n{{/each}}\n\n{{#if showAllergyInfo}}\n  <th></th>\n  <th class=\"align-top\"><strong>{{amountOfVegetarians}}</strong></th>\n{{/if}}", {"contents":"{{#if showCheckbox}}\n  <th></th>\n{{/if}}\n\n<th class=\"align-top\"><strong>{{form.amountOfResponses}}</strong></th>\n\n{{#each form.sortedQuestions as |question|}}\n  <th class=\"align-top\">\n    {{#if question.isOpenQuestion}}\n      <strong>{{question.sumOfAnswers}}</strong>\n    {{else}}\n      {{#each question.sortedOptions as |opt|}}\n        <strong>{{opt.sumOfAnswers}}</strong>\n        {{opt.option}}<br />\n      {{/each}}\n    {{/if}}\n  </th>\n{{/each}}\n\n{{#if showAllergyInfo}}\n  <th></th>\n  <th class=\"align-top\"><strong>{{amountOfVegetarians}}</strong></th>\n{{/if}}","moduleName":"amber-ui/components/form/responses-table-totals.hbs","parseOptions":{"srcName":"amber-ui/components/form/responses-table-totals.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

const ResponsesTableTotalsComponent = Component.extend({
  tagName: 'tr',
  form: null,
  amountOfVegetarians: computed('form.responses', function () {
    return this.form
      .get('responses')
      .filterBy('user.vegetarian', true)
      .filterBy('completed', true).length;
  }),
});

ResponsesTableTotalsComponent.reopenClass({
  positionalParams: ['form'],
});

export default ResponsesTableTotalsComponent;
